import { BehaviorSubject } from 'rxjs';
import { debugAdd } from '~src/helpers/debug';

export interface ScreenInfo {
    width: number,
    height: number,
}

export const defaultScreenInfo: ScreenInfo = {
    width: 1080,
    height: 1920,
}

export const screenInfo$ = new BehaviorSubject<ScreenInfo>(defaultScreenInfo);

export function getScreenInfo() {
    return screenInfo$.value;
}

export function setScreenInfo(screenInfo?: ScreenInfo) {
    if (screenInfo$.value !== screenInfo) screenInfo$.next(screenInfo||defaultScreenInfo);
}

debugAdd({ screenInfo$ });

export default screenInfo$;