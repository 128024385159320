import React from 'react';
import { TextField } from '@mui/material';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function NumberField({ label, itemId, prop }: FieldProp) {
	return (
		<PropDelete itemId={itemId} prop={prop}>
			<TextField
				id={`${itemId}-${String(prop)}`}
				label={label}
				value={nodes.getPropNumber(itemId, prop, '')}
				onChange={(e) => nodes.setPropNumber(itemId, prop, e.target.value)}
				type="number"
				size="small"
			/>
		</PropDelete>
	);
}
