import * as React from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import { Text } from './BlockText';
import useNode from '~src/hooks/useNode';
import addCss from '~src/helpers/addCss';

addCss('BlockButton', `.b--button {}`);

export default function BlockButton(props: BlockProps) {
	const { text, color, fontSize } = useNode(props.itemId);
	return (
		<Block {...props}>
			{text && <Text fontSize={fontSize} text={text} color={color} />}
		</Block>
	);
}
