import { useEffect } from 'react';

export default function useEventListener<T extends HTMLElement>(el: T|null|undefined, type: string, handle: () => void) {
	useEffect(() => {
		if (el) {
			el.addEventListener(type, handle);
			return () => {
				el.removeEventListener(type, handle);
			};
		}
	}, [el, type, handle]);
}
