import { BehaviorSubject, switchMap } from 'rxjs';
import nodes from '~src/controllers/NodesController';
import { debugAdd } from '~src/helpers/debug';

export const selectedId$ = new BehaviorSubject('');
export default selectedId$;

// Remove selectedId if change and not exists
selectedId$.pipe(switchMap(selectedId => nodes.get$(selectedId))).subscribe((selected) => setSelectedId(selected.id));

export function getSelectedId() {
    return selectedId$.value;
}

export function setSelectedId(selectedId?: string) {
    const value = nodes.exists(selectedId) ? selectedId || '' : '';
    if (selectedId$.value !== value) selectedId$.next(value);
}

// export function selectedUp() {
//     console.debug('selectedUp');
//     const id = getSelectedId();
//     const index = nodes.getIndex(id);
//     if (index !== -1) {
//         const childIds = nodes.getParentChildIds(id);
//         const endIndex = childIds.length - 1;
//         const nextIndex = index === 0 ? endIndex : index - 1;
//         setSelectedId(childIds[nextIndex]);
//     }
// }

// export function selectedDown() {
//     console.debug('selectedDown');
//     const id = getSelectedId();
//     const index = nodes.getIndex(id);
//     if (index !== -1) {
//         const childIds = nodes.getParentChildIds(id);
//         const endIndex = childIds.length - 1;
//         const nextIndex = index === endIndex ? 0 : index + 1;
//         setSelectedId(childIds[nextIndex]);
//     }
// }

// export function selectedLeft() {
//     console.debug('selectedLeft');
//     const id = getSelectedId();
//     const node = nodes.get(id);
//     if (node && node.parentId) setSelectedId(node.parentId);
// }

// export function selectedRight() {
//     console.debug('selectedRight');
//     const id = getSelectedId();
//     const node = nodes.get(id);
//     if (node && node.childIds && node.childIds[0]) setSelectedId(node.childIds[0]);
// }

// export function selectedMoveUp() {
//     console.debug('selectedMoveUp');
//     const id = getSelectedId();
//     if (!id) return;
//     const index = nodes.getIndex(id);
//     if (index === -1) return;
//     const childIds = nodes.getParentChildIds(id);
//     const endIndex = childIds.length - 1;
//     const nextIndex = index === 0 ? endIndex : index - 1;
//     nodes.setIndex(id, nextIndex);
// }

// export function selectedMoveDown() {
//     console.debug('selectedMoveDown');
//     const id = getSelectedId();
//     if (!id) return;
//     const index = nodes.getIndex(id);
//     if (index === -1) return;
//     const childIds = nodes.getParentChildIds(id);
//     const endIndex = childIds.length - 1;
//     const nextIndex = index === endIndex ? 0 : index + 1;
//     nodes.setIndex(id, nextIndex);
// }

debugAdd({ selectedId$ });