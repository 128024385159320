import React from 'react';
import BlockFactory from './BlockFactory';
import BlockProps from './BlockProps';
import clsx from '~src/helpers/clsx';
import useNode from '~src/hooks/useNode';
import { nbr } from '~src/helpers/converters';
import addCss from '~src/helpers/addCss';
import { actionClick } from '~src/messages/action$';
import { setNodeEl } from '~src/messages/nodeEl$';

addCss(
	'block',
	`
.b {
background-position: center;
background-repeat: no-repeat;
background-size: cover;
box-sizing: border-box;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
overflow: hidden;
position: absolute;
left: 0%;
top: 0%;
width: 100%;
height: 100%;
}
.b--row, .b--column { display: flex; flex-direction: row; }
.b--column { flex-direction: column; }
.b--row > .b, .b--column > .b { position: relative; }
`,
);

export default function Block(props: BlockProps) {
	console.debug('Block', props);

	const itemId = props.itemId;
	let className = props.className;
	let children = props.children;
	const node = useNode(itemId);

	const type = node.type || 'block';
	let layout = node.layout || 'absolute';
	let parentLayout = useNode(node.parentId).layout || 'absolute';

	// console.debug('Block', { type, dataAttrs, dataChildren, x, y, w, h, layout, parentLayout });
	const s = { ...props.style, ...node.style };
	const rProps: JSX.IntrinsicElements['div'] = {
		...props.elProps,
		...node.attrs,
		className: clsx('b', 'b--' + type, layout !== 'absolute' && 'b--' + layout, className, node.class),
		style: s,
		onClick: (e: React.MouseEvent<any>) => {
			actionClick(e, itemId);
		},
		ref: (el) => setNodeEl(itemId, el),
	};

	switch (layout) {
		case 'absolute':
			break;
		case 'column':
		case 'row':
			if (node.flexInline) s.display = 'inline-flex';
			if (node.flexReverse) s.flexDirection = layout === 'column' ? 'column-reverse' : 'row-reverse';
			if (node.justifyContent) s.justifyContent = node.justifyContent;
			if (node.alignContent) s.alignContent = node.alignContent;
			if (node.alignItems) s.alignItems = node.alignItems;
			if (node.flexWrap) s.flexWrap = node.flexWrap;
			break;
	}

	switch (parentLayout) {
		case 'absolute':
			const x = nbr(node.x, 0);
			const y = nbr(node.y, 0);
			const w = nbr(node.w, 100);
			const h = nbr(node.h, 100);
			if (x !== 0) s.left = x + '%';
			if (y !== 0) s.top = y + '%';
			if (w !== 100) s.width = w + '%';
			if (h !== 100) s.height = h + '%';
			break;
		case 'column':
		case 'row':
			s.flex = node.flex;
			const fw = nbr(node.w, 100);
			const fh = nbr(node.h, 100);
			if (fw !== 100) s.width = fw + '%';
			if (fh !== 100) s.height = fh + '%';
			break;
	}

	if (node.bgColor) {
		s.backgroundColor = node.bgColor;
	}

	if (node.html) {
		rProps.dangerouslySetInnerHTML = { __html: node.html };
		children = undefined;
	} else if (!children && node.childIds) {
		children = node.childIds.map((childId) => <BlockFactory key={childId} itemId={childId} />);
	}

	return React.createElement(props.elType || 'div', rProps, children);
}
