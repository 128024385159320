import React, { useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import nodes from '~src/controllers/NodesController';

export default function JsonEditor({ itemId }: { itemId: string }) {
	const [json, setJson] = useState('');
	return (
		<TextareaAutosize
			id={`${itemId}-data`}
			value={json || JSON.stringify(nodes.getData(itemId), null, 4)}
			onChange={(e) => {
				const json = e.target.value;
				setJson(json);
				try {
					nodes.update(itemId, JSON.parse(json));
				} catch (_) {}
			}}
			onBlur={() => setJson('')}
		/>
	);
}