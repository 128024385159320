import { Button, LinearProgress, TextField } from '@mui/material';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import useObservable from '~src/hooks/useObservable';
import { storageTransferred$, storageUpload } from '~src/services/storage';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function FileField({ label, itemId, prop }: FieldProp) {
	const progress = useObservable(storageTransferred$) || 0;

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (!file) return;
        nodes.setPropString(itemId, prop, undefined);
        storageUpload(file).then((url) => nodes.setPropString(itemId, prop, url));
	};

	return (
		<>
			<PropDelete itemId={itemId} prop={prop}>
				<TextField
					id={`${itemId}-${String(prop)}`}
					label={label}
					value={nodes.getPropString(itemId, prop, '')}
					onChange={(e) => nodes.setPropString(itemId, prop, e.target.value)}
				/>
				<input
					style={{ display: 'none' }}
					id={`${itemId}-${String(prop)}-file`}
					name="upload"
					type="file"
					onChange={handleFileChange}
				/>
				<label htmlFor={`${itemId}-${String(prop)}-file`}>
					<Button size="small" color="secondary" variant="contained" component="span">
						Télécharger
					</Button>
				</label>
			</PropDelete>
			{progress > 0 && progress < 1 ? <LinearProgress variant="determinate" value={progress * 100} /> : null}
		</>
	);
}
