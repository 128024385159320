

import React, { HTMLAttributes } from 'react';
import addStyleFile from '~src/helpers/addStyleFile';
import clsx from "~src/helpers/clsx";

addStyleFile('https://fonts.googleapis.com/icon?family=Material+Icons');

export function getIcon(name: string) {
  return () => <Icon name={name} />;
}

export default function Icon({ name, ...attrs }: HTMLAttributes<HTMLElement> & { name: string }) {
    return (
      <span {...attrs} className={clsx('material-icons icon', attrs.className)}>{name}</span>
    );
  }
  