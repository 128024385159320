import * as React from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import useDownload from '~src/hooks/useDownload';
import addCss from '~src/helpers/addCss';
import useNode from '~src/hooks/useNode';

addCss('BlockImage', `.b--image { background-size: contain; background-position: center; }`);

export default function BlockImage(props: BlockProps) {
	const data = useNode(props.itemId);
	const file = useDownload(data.url);
	return <Block {...props} style={{ ...props.style, backgroundImage: `url(${file.blobUrl})` }} />;
}
