import { BehaviorSubject, firstValueFrom, filter } from 'rxjs';
import nodes from '~src/controllers/NodesController';
import Node from '~src/models/Node';

export const nodeEl$Map: { [id: string]: BehaviorSubject<HTMLElement | undefined> } = {};

export default function nodeEl$(id?: string) {
    return nodeEl$Map[id || ''] || (nodeEl$Map[id || ''] = new BehaviorSubject<HTMLElement | undefined>(undefined));
}

export function setNodeEl(id?: string, el?: HTMLElement | null) {
    if (el) (el as any).__nodeId = id;
    nodeEl$(id).next(el || undefined);
}

export function getNodeEl(id?: string) {
    return nodeEl$(id).value;
}

export async function getNodeElAsync(id: string) {
    let el = getNodeEl(id);
    if (el) return el;
    el = await firstValueFrom(nodeEl$(id).pipe(filter(p => !!p)));
    return el as HTMLElement;
}

export function nodeElToNode(el?: any): Node | undefined {
    console.debug('nodeElToNode', el);
    if (!el) return;
    while (el) {
        const id = (el as any).__nodeId;
        if (nodes.exists(id)) return nodes.get(id);
        el = el.parentElement;
    }
}




// elMap: Record<string, HTMLElement|undefined> = {};

// setEl(id: string, el?: HTMLElement) {
//     if (el) {
//         (el as any).__nodeId = id;
//         this.elMap[id] = el;
//     }
// }

// getEl(id?: string) {
//     // console.debug('getEl', id, id && this.elMap[id]);
//     return id ? this.elMap[id] : undefined;
// }

// getEl$(id: string) {
//     if (!id) id = '';
//     if (!this.nodeMap[id]) id = '';
//     return this.el$Map[id] || (this.el$Map[id] = new BehaviorSubject<HTMLElement | undefined>(this.getEl(id)));
// }

