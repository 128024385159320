import * as React from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import useNode from '~src/hooks/useNode';
import addCss from '~src/helpers/addCss';

addCss('BlockYouTube', `
.b--youtube { }
.b__youtube-iframe {
margin: 0;
width: 100%;
height: 100%;
}
`);

function getYoutubeCode(url: string) {
	return ((url.match(/embed\/([^"]+)/) || url.match(/youtu\.be\/([^?]+)/) || url.match(/v=([^&]+)&/)) || [])[1] || url;
}

export default function BlockYouTube(props: BlockProps) {
	const {url} = useNode(props.itemId);
	const code = getYoutubeCode(String(url));

	console.debug('BlockYouTube', {url, code});

	return (
		<Block {...props}>
			{code && (
				<iframe
					className="b__youtube-iframe"
					title={"youtube:"+code}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					scrolling="no"
					src={`https://www.youtube.com/embed/${code}?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&loop=1&end=0&controls=0&modestbranding=1&origin=https://boardscreen.fr/`}
				></iframe>
			)}
		</Block>
	);
}