import { BehaviorSubject } from 'rxjs';
import { debugAdd } from '~src/helpers/debug';
import nodes, { isNodePage, isNodeVisible, NULL_NODE } from '../controllers/NodesController';
import selectedId$ from './selectedId$';

export const pageId$ = new BehaviorSubject('');

function getPageId() {
    return pageId$.value;
}

function setPageId(pageId?: string) {
    return pageId$.next(pageId||'');
}

nodes.root$().subscribe(() => {
    if (nodes.exists(getPageId())) return;
    const page = nodes.getPages().filter(isNodeVisible)[0];
    if (page) setPageId(page.id);
});

selectedId$.subscribe(selectedId => {
    const page = nodes.findParent(selectedId, n => isNodePage(n) && isNodeVisible(n));
    if (page && page !== NULL_NODE) pageId$.next(page.id);
});

export function nextPage() {
    const pages = nodes.getPages().filter(isNodeVisible);
    const pageId = getPageId();
    const pageIndex = pages.findIndex(p => p.id === pageId);
    const nextIndex = (pageIndex + 1) % pages.length;
    const next = pages[nextIndex];
    if (pageId !== next.id) pageId$.next(next.id);
}

debugAdd({ pageId$ });

export default pageId$;