import bridge from '../services/bridge';

export interface FileDownloaded {
	url?: string;
	localUrl?: string;
	blob?: Blob;
	blobUrl?: string;
	error?: any;
}

export class FilesController {
	cache: { [url: string]: Promise<FileDownloaded> } = {};

	private async _download(url: string): Promise<FileDownloaded> {
		try {
			let localUrl = '';

			if (bridge && bridge.download) {
				localUrl = await bridge.download(url);
				if (localUrl) {
					url = localUrl;
				}
			}

			const res = await fetch(url);
			if (!res.ok) throw new Error('response not ok');

			const blob = await res.blob();
			if (!blob) throw new Error('no blob');

			const blobUrl = URL.createObjectURL(blob);

			return { url, localUrl, blob, blobUrl };
		} catch (error) {
			console.error('files.getUrl', url, error);
			return { url, error };
		}
	}

	async download(url?: string): Promise<FileDownloaded> {
		if (!url) return Promise.resolve({ url });
		return this.cache[url] || (this.cache[url] = this._download(url));
	}
}

export const files = new FilesController();
export default files;
(window as any)._files = files;
