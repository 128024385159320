import React from 'react';
import { PageContainer } from '../Page';
import NewAdminPage from './NewAdminPage';

export default function AdminPage() {
	return (
		<PageContainer>
			<NewAdminPage />
		</PageContainer>
	);
}
