import { BehaviorSubject } from 'rxjs';
import { debugAdd } from '~src/helpers/debug';
import { updateUrlParams, urlParams$ } from '~src/hooks/useUrlParams';

export const deviceId$ = new BehaviorSubject(urlParams$.value.deviceId || '');

urlParams$.subscribe(urlParams => {
    const deviceId = deviceId$.value;
    if (urlParams.deviceId !== deviceId) {
        console.debug('deviceId$ from urlParams', { urlParams, deviceId });
        deviceId$.next(urlParams.deviceId);
    }
});

deviceId$.subscribe(deviceId => {
    const urlParams = urlParams$.value;
    if (urlParams.deviceId !== deviceId) {
        console.debug('deviceId$ to urlParams', { urlParams, deviceId });
        updateUrlParams({ deviceId });
    }
});

debugAdd({ deviceId$ });

export default deviceId$;