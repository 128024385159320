import React from 'react';
import useObservable from '~src/hooks/useObservable';
import currentUser$ from '~src/messages/currentUser$';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import pageMode$, { PageMode } from '~src/messages/pageMode$';
import ViewerPage from './Pages/ViewerPage';
import SignPage from './Pages/SignPage';
import AdminPage from './Pages/AdminPage';

console.debug('App:init');

// const SignPage = React.lazy(() => import('src/components/Pages/SignPage'));
// const AdminPage = React.lazy(() => import('src/components/Pages/AdminPage'));

const theme = createTheme({
	palette: {
		primary: {
			main: '#e26329',
		},
		secondary: {
			main: lightBlue[800],
		},
	},
});

function Router() {
	const currentUser = useObservable(currentUser$);
	const pageMode = useObservable(pageMode$);

	console.debug('Router', { currentUserId: currentUser?.uid, pageMode });

	if (pageMode === PageMode.View) return <ViewerPage />;
	if (!currentUser) return <SignPage />;
	return <AdminPage />;
}

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router />
		</ThemeProvider>
	);
}
