import currentUser$ from './currentUser$';
import { map, switchMap } from 'rxjs';
import { dbObserveAny } from '~src/services/db';
import { debugAdd } from '~src/helpers/debug';

export const adminRole$ = currentUser$.pipe(
	switchMap((user) =>
        dbObserveAny(`admins/${user?.uid}`).pipe(
			map((isAdmin) => ({
				id: user?.uid,
				isAdmin: isAdmin === true,
				user,
			})),
		),
	),
);

debugAdd({ adminRole$ });

export default adminRole$;