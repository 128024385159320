import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function SwitchField({ label, itemId, prop }: FieldProp) {
	return (
		<PropDelete itemId={itemId} prop={prop}>
			<FormControlLabel
				id={`${itemId}-${String(prop)}`}
				value={!!nodes.getProp(itemId, prop)}
				onChange={(e, checked: boolean) => nodes.setProp(itemId, prop, checked)}
				control={<Switch color="primary" />}
				label={label}
				labelPlacement="start"
			/>
		</PropDelete>
	);
}
