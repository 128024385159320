import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import { nbr } from '~src/helpers/converters';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

const useStyles = makeStyles(() => ({
	days: {
		display: 'flex',
		margin: 4,
		flex: 1,
		'& .MuiButtonBase-root': {
			flex: 1,
			height: 60,
			overflow: 'hidden',
			padding: 0,
		},
		'& i': {
			fontSize: '0.8em',
			transform: 'rotate(-60deg)',
			margin: -10,
		}
	}
}));

const dayNames = [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ];

export default function IntervalField({ label, itemId, prop }: FieldProp) {
	const classes = useStyles();
    const days = nodes.getPropString(itemId, prop, '').split('').map(nbr) as number[];
	console.debug('IntervalField', { days });

	// 1<<0=1:Lundi
	// 1<<1=2:Mardi
	// 1<<2=4:Mercredi
	// 1<<3=8:Jeudi
	// 1<<4=16:Vendredi
	// 1<<5=32:Samedi
	// 1<<6=64:Dimanche

    return <PropDelete itemId={itemId} prop={prop}>
		<ToggleButtonGroup
			className={classes.days}
			color="primary"
			value={days}
			exclusive
			size='small'
			onChange={(e, v) => {
				const n = nbr(v);
				const i = days.indexOf(n);
				const r = [...days];
				if (i === -1) r.push(n);
				else r.splice(i, 1);
				r.sort((a,b) => a - b);
				const s = r.join('');
				console.debug('IntervalField onChange', {v, n, i, r, s});
				nodes.setPropString(itemId, prop, s);
			}}
		>
			{[1,2,3,4,5,6,0].map((i) => <ToggleButton key={i} value={i}><i>{dayNames[i]}</i></ToggleButton>)}
		</ToggleButtonGroup>



	</PropDelete>;
	// return (
	// 	<LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
	// 		<DatePicker
	// 			label={label}
	// 			value={date}
	// 			onChange={(date) => {
	// 				console.debug('DateField onChange', prop, date);
    //                 const value = isDate(date) ? date.getTime() : null;
    //                 nodes.update(itemId, { [prop]: value })
	// 			}}
	// 			renderInput={(params) => <TextField id={`${itemId}-${String(prop)}`} {...params} />}
	// 		/>
	// 	</LocalizationProvider>
	// );
}