import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import { SketchPicker } from 'react-color';
import PropDelete from './PropDelete';
import { colorBrightness } from '~src/services/color';

export default function ColorField({ label, itemId, prop }: FieldProp) {
	const [show, setShow] = useState(false);

	const presetColors = useMemo(() => {
		if (!itemId) return [];
		const colorMap: { [colorHex: string]: boolean } = {};
		nodes.getNodes().forEach((node) => {
			if (node.color) colorMap[node.color] = true;
			if (node.bgColor) colorMap[node.bgColor] = true;
		});
		return Object.keys(colorMap);
	}, [itemId]);

	const val = nodes.getPropString(itemId, prop, '');
	const backgroundColor = val || '#FFFFFF';
	
	return (
        <div id={`${itemId}-${String(prop)}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<PropDelete itemId={itemId} prop={prop}>
				<Button
                    variant={colorBrightness(backgroundColor) < 128 ? "contained" : "outlined"}
                    style={{ flex: 1, backgroundColor }}
                    onClick={() => setShow((show) => !show)}>
					{label}
				</Button>
			</PropDelete>
			{show && (
				<SketchPicker
					color={val}
					onChange={(colorRes) => nodes.setPropString(itemId, prop, colorRes.hex)}
					presetColors={presetColors}
				/>
			)}
		</div>
    );
}