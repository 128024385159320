let _tr: { [ctx: string]: { [key: string]: string } } = {
	default: {},
};
(window as any)._tr = _tr;

export function addTr(tr: { [key: string]: string }, ctx: string = 'default') {
	_tr[ctx] = { ..._tr[ctx], ...tr };
}

export const useTr = (ctx: string = 'default') => {
	const tr = _tr[ctx] || (_tr[ctx] = {});
	const f = (key: string, params?: { [key: string]: any }, fr?: string) => {
		const t = tr[key] || (tr[key] = fr || key);
		return params ? Object.entries(params).reduce((a, [k, v]) => a.replace(`{${k}}`, v), t) : t;
	};
	f.date = (d: any) => (d ? (d.toDate ? d.toDate() : d).toJSON().replace('T', ' ').substr(0, 19) : '');
	return f;
};

addTr({
	loading: 'Chargement...',
	prop_name: 'Étiquette',
	prop_type: 'Type',
	prop_layout: 'Type',
	prop_x: 'Gauche (%)',
	prop_y: 'Haut (%)',
	prop_w: 'Largeur (%)',
	prop_h: 'Hauteur (%)',
	prop_style: 'Style',
	prop_text: 'Texte',
	prop_icon: 'Icon',
	prop_bgColor: 'Arrière plan',
	prop_color: 'Couleur',
	prop_fontSize: 'Taille du texte',
	prop_iconSize: 'Taille de l’icon',
	prop_justifyContent: 'Justifier',
	prop_alignItems: 'Aligner',
	prop_flexWrap: 'Plusieurs lignes',
	prop_alignContent: 'Aligner les lignes',
	prop_flexInline: 'En ligne',
	prop_flexReverse: 'Inverser',
	prop_onClick_sendTo: 'Bloc cible',
	prop_onClick_web_history: 'Navigation',
	prop_onClick_web_url: 'URL',
	legend_position: 'Position',
	legend_layout: 'Disposition',
	legend_advanced: 'Paramètres avancés',
	type_block: 'Bloc',
	type_button: 'Bouton',
	type_carousel: 'Carousel',
	type_icon: 'Icon',
	type_image: 'Image',
	type_pdf: 'PDF',
	type_text: 'Texte',
	type_web: 'Page WEB',
	type_root: 'Pages',
	type_page: 'Page',
	layout_absolute: 'Position',
	layout_column: 'En colone',
	layout_row: 'En ligne',
	layout_center: 'Centre',
	justifyContent_flexstart: 'Début',
	justifyContent_flexend: 'Fin',
	justifyContent_center: 'Centre',
	justifyContent_spacebetween: 'Espace entre',
	justifyContent_spacearound: 'Espace autour',
	alignItems_flexstart: 'Début',
	alignItems_flexend: 'Fin',
	alignItems_center: 'Centre',
	alignItems_baseline: 'Ligne de base',
	alignItems_stretch: 'Étendue',
	flexWrap_nowrap: 'Non',
	flexWrap_wrap: 'Oui',
	flexWrap_wrapreverse: 'Inverser',
	alignContent_flexstart: 'Début',
	alignContent_flexend: 'Fin',
	alignContent_center: 'Centre',
	alignContent_spacebetween: 'Espace entre',
	alignContent_spacearound: 'Espace autour',
	alignContent_stretch: 'Étendue',
	flexInline_true: 'Oui',
	flexInline_false: 'Non',
	flexReverse_true: 'Oui',
	flexReverse_false: 'Non',
	prop_url: 'URL',
	prop_interval: 'Intervalle (ms)',
	prop_html: 'Contenu',
	sign_inTitle: 'Connectez-vous',
	sign_upTitle: 'Inscrivez-vous',
	sign_forgotTitle: 'Récupérer votre compte',
	sign_firstName: 'Prénom',
	sign_lastName: 'Nom',
	sign_email: 'E-Mail',
	sign_password: 'Mot de passe',
	sign_rememberMe: 'Se souvenir de moi',
	sign_inSubmit: 'Je me connecte',
	sign_upSubmit: 'Je m’inscris',
	sign_forgotSubmit: 'Envoyer le mail de récupération',
	sign_forgotLink: 'Mot de passe oublié ?',
	sign_upLink: 'Vous n’êtes pas inscrit ?',
	sign_inLink: 'Revenir à l’écran de Connexion',
	sign_resetPasswordSubmit: 'Réinitialiser le mot de passe',
	sign_emailAlreadyInUse: 'L’adresse e-mail est déjà utilisée par un autre compte.',
	sign_wrongPassword: 'Le mot de passe n’est pas valide ou l’utilisateur n’a pas de mot de passe.',
	sign_invalidActionCode:
		'Le code d’action n’est pas valide. Cela peut se produire si le code est mal formé, expiré ou a déjà été utilisé.',
	sign_expiredActionCode: 'Le code d’action a expiré.',
	topBar_logout: 'Déconnexion',
});
