const _cssMap: { [key: string]: { el: HTMLElement, css: string } } = {};
(window as any)._cssMap = _cssMap;

export default function addCss(key: string, css: string) {
    const old = _cssMap[key];
    if (old) {
        if (old.css === css) return;
        old.el.remove();
    }
    const el = document.createElement('style');
    el.textContent = css;
    document.head.appendChild(el);
    _cssMap[key] = { el, css };
}