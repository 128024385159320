import * as React from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import useWindowSize from '~src/hooks/useWindowSize';
import { useRef } from 'react';
import useNode from '~src/hooks/useNode';
import useTimer from '~src/hooks/useTimer';
import addCss from '~src/helpers/addCss';

addCss('b--text', `
.b--text { font-size: 80%; line-height: 1; }
.b__text-fit { width: 100%; height: 100%; }
.b__text {  }
.b__html {  }
`);

export function TextFit({ text, color }: { text: string; color?: string }) {
	const ref = useRef<SVGSVGElement|null>(null);
	useWindowSize();
	useTimer(1000);
	let viewBox = '0 0 56 40';
	if (ref.current) {
		const w = ref.current.children[0].clientWidth;
		viewBox = `0 0 ${w} 40`;
	}
	return (
		<svg ref={ref} className="b__text-fit" viewBox={viewBox}>
			<text
				x="0"
				y="20"
				alignmentBaseline="central"
				color={color}
				style={{
					fill: color,
					fontSize: 40,
					lineHeight: 1,
				}}
			>
				{text}
			</text>
		</svg>
	);
}

export function Text({
	text,
	fontSize,
	color,
}: {
	text: string | { html: string };
	fontSize?: number;
	color?: string;
}) {
	return typeof text === 'object' ? (
		<div className="b__html" style={{ fontSize, color }} dangerouslySetInnerHTML={{ __html: text.html }} />
	) : !fontSize ? (
		<TextFit text={text} color={color} />
	) : (
		<div className="b__text" style={{ fontSize, color }}>
			{text}
		</div>
	);
}

export default function BlockText(props: BlockProps) {
	const { text, color, fontSize } = useNode(props.itemId);
	return (
		<Block {...props}>
			<Text fontSize={fontSize} text={text||''} color={color} />
		</Block>
	);
}
