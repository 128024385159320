import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getRegisterTypes } from '~src/components/Block/BlockFactory';
import { useTr } from '~src/services/tr';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button, { ButtonProps } from '@mui/material/Button';
import { colorRandom } from '~src/services/color';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DuplicateIcon from '@mui/icons-material/Filter2';
import CopyIcon from '@mui/icons-material/CloudUpload';
import PasteIcon from '@mui/icons-material/CloudDownload';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/Forward';
import nodes from '~src/controllers/NodesController';
import useObservable from '~src/hooks/useObservable';
import uid from '~src/helpers/uid';
import { NType } from '~src/models/Node';
import pageId$ from '~src/messages/pageId$';
import useNode from '~src/hooks/useNode';
import selectedId$, { setSelectedId } from '~src/messages/selectedId$';
import PropColumn from './fields/PropColumn';
import PropRow from './fields/PropRow';
import InputField from './fields/InputField';
import SelectField from './fields/SelectField';
import ColorField from './fields/ColorField';
import FileField from './fields/FileField';
import IntervalField from './fields/IntervalField';
import NumberField from './fields/NumberField';
import DateField from './fields/DateField';
import TimeField from './fields/TimeField';
import DaysField from './fields/DaysField';
import SwitchField from './fields/SwitchField';
import JsonEditor from './fields/JsonEditor';
import typeInfoMap$ from '~src/messages/typeInfoMap$';
import VideoField from './fields/VideoField';
import useConstant from '~src/hooks/useConstant';

const layoutOptions = ['absolute', 'column', 'row', 'center'];
const flexWrapOptions = ['nowrap', 'wrap', 'wrap-reverse'];
const justifyContentOptions = ['flex-start', 'flex-end', 'center', 'space-between', 'space-around'];
const alignItemsOptions = ['flex-start', 'flex-end', 'center', 'baseline', 'stretch'];
const alignContentOptions = ['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'stretch'];

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		// padding: theme.spacing(),
		borderTop: '1px solid black',
		overflow: 'auto',
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		flexDirection: 'column',
		margin: theme.spacing(0.5),
		'& .MuiButton-root': {
			margin: theme.spacing(0.5),
		},
	},
	legend: {
		fontWeight: 'bold',
		textAlign: 'center',
		paddingTop: theme.spacing(1),
	},
	prop: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: theme.spacing(),
		'& label': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			width: '100%',
		},
		'& span': {
			width: 150,
			marginTop: 4,
		},
		'& .input': {
			flex: 1,
		},
		'& span.MuiButton-root': {
			width: 'calc(100% - 100px)',
			marginLeft: 100,
		},
		'& span.MuiButton-root, & span.MuiButton-label, & span.MuiIcon-root': {
			width: '100%',
			marginTop: 0,
		},
	},
	buttonUpload: {
		width: '100%',
	},
}));

function HtmlEditor({ itemId }: { itemId: string }) {
	return (
		<>
			<p>HTML:</p>
			<TextareaAutosize
				id={`${itemId}-html`}
				value={nodes.get(itemId).html || ''}
				onChange={(e) => nodes.update(itemId, { html: e.target.value || undefined })}
			/>
		</>
	);
}

function TextEditor({ title, prop, itemId }: { title: string, prop: string, itemId: string }) {
	const info = useConstant(() => ({ timer: null as any }));
	const [value, setValue] = useState((nodes.get(itemId) as any)[prop] || '');

	useEffect(() => {
		clearTimeout(info.timer);
		info.timer = setTimeout(() => {
			nodes.update(itemId, { [prop]: value || undefined });
		}, 500);
	}, [itemId, prop, info, value]);

	return (
		<>
			<p>{title}</p>
			<TextareaAutosize
				id={`${itemId}-${prop}`}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
		</>
	);
}

function EditorButton(props: ButtonProps) {
	return <Button fullWidth variant="contained" size="small" {...props}></Button>;
}

function EditorPropertiesWithItem({ itemId }: { itemId: string }) {
	const classes = useStyles();

	const [isAdvances, setIsAdvances] = useState(false);

	const node = useNode(itemId);
	const type = node.type || 'block';
	const layout = node.layout || 'absolute';
	const pageId = useObservable(pageId$) || itemId;
	const parentId = node.parentId;
	const parentLayout = useNode(parentId).layout || 'absolute';
	const tr = useTr();

	const typeInfoMap = useObservable(typeInfoMap$);
	const typeLabel = (typeInfoMap && typeInfoMap[type]?.label) || '';
	
// const typeToText: { [type: string]: string } = {
// 	block: 'élément',
// 	web: 'page web',
// 	pdf: 'pdf',
// 	video: 'video',
// 	youtube: 'YouTube',
// 	image: 'image',
// 	text: 'text',
// 	icon: 'icon',
// 	button: 'bouton',
// 	carousel: 'carousel',
// 	root: 'pages',
// 	page: 'page',
// };


// const typeToLabel: { [type: string]: string } = {
// 	block: 'l’élément',
// 	web: 'la page web',
// 	pdf: 'le pdf',
// 	video: 'la video',
// 	youtube: 'YouTube',
// 	image: 'l’image',
// 	text: 'le text',
// 	icon: 'l’icon',
// 	button: 'le bouton',
// 	carousel: 'le carousel',
// 	root: 'les pages',
// 	page: 'la page',
// };


	console.debug('EditorPropertiesWithItem', itemId, layout, parentLayout);

	const handlePageAdd = () => {
		const bgColor = colorRandom();
		console.debug('handlePageAdd', bgColor);
		const id = uid();
		nodes.add(pageId, {
			id,
			name: 'Élément',
			type: NType.Block,
			x: 25,
			y: 25,
			w: 50,
			h: 50,
			bgColor,
		});
		setSelectedId(id);
	};

	const handleAdd = () => {
		const bgColor = colorRandom();
		console.debug('handleAdd', bgColor);
		const id = uid();
		nodes.add(itemId, {
			id,
			type: NType.Block,
			x: 25,
			y: 25,
			w: 50,
			h: 50,
			bgColor,
		});
		setSelectedId(id);
	};

	const handleRemove = () => {
		nodes.remove(itemId);
	};

	const handleDuplicate = () => {
		const item = nodes.duplicate(itemId);
		const m = item.name?.match(/^(.*)([0-9]+)$/);
		const name = m ? m[1] + (parseInt(m[2]) + 1) : item.name + ' 2';
		nodes.update(item.id, { name });
	};

	const handleAdvances = () => {
		setIsAdvances((value) => !value);
	};

	const handleCopy = () => {
		const itemJson = JSON.stringify(nodes.exportData(itemId));
		localStorage.setItem('itemCopy', itemJson);
	};

	const handlePaste = () => {
		const parentId = nodes.get(itemId).parentId;
		if (parentId) {
			const itemJson = localStorage.getItem('itemCopy');
			if (!itemJson) return;
			const item = JSON.parse(itemJson);
			nodes.importData(item, parentId);
		}
	};

	const handleUp = () => {
		const index = nodes.getIndex(itemId);
		if (index > 0) nodes.setIndex(itemId, index - 1);
	};

	const handleDown = () => {
		const index = nodes.getIndex(itemId);
		const length = nodes.get(nodes.get(itemId).parentId).childIds?.length || 0;
		if (index < length - 1) nodes.setIndex(itemId, index + 1);
	};

	const sendTo = nodes.getByName(node.onClick?.sendTo);
	const sendToType = sendTo?.type;
	console.debug('sendToType', node, sendTo, sendToType);

	return (
		<div className={classes.root}>
			<PropColumn>
				<EditorButton onClick={handlePageAdd} color="primary" startIcon={<AddIcon />}>
					Ajouter dans la page
				</EditorButton>
				<InputField itemId={itemId} prop="name" label="Nom de la zone" />
				<SelectField
					itemId={itemId}
					prop="type"
					label="Type de media"
					options={getRegisterTypes().map((value) => ({ value, text: (typeInfoMap && typeInfoMap[value]?.title) || value }))}
				/>
				<ColorField itemId={itemId} prop="bgColor" label="Couleur de l'arrière plan" />
				{type === 'root' && (
					<>
						<InputField itemId={itemId} prop="url" label="URL" />
					</>
				)}
				{type === 'web' && (
					<>
						<InputField itemId={itemId} prop="url" label="URL" />
					</>
				)}
				{type === 'youtube' && (
					<>
						<InputField itemId={itemId} prop="url" label="URL de YouTube" />
					</>
				)}
				{type === 'video' && (
					<>
						<VideoField itemId={itemId} prop="url" label="URL de la vidéo" />
					</>
				)}
				{type === 'pdf' && (
					<>
						<IntervalField itemId={itemId} prop="interval" label="Durée en seconde" />
						<FileField itemId={itemId} prop="url" label="URL du PDF" />
					</>
				)}
				{type === 'text' && (
					<>
						<ColorField itemId={itemId} prop="color" label="Couleur du texte" />
						<InputField itemId={itemId} prop="text" label="Texte" />
						<NumberField itemId={itemId} prop="fontSize" label="Taille du texte" />
						{/* <BlockProp itemId={itemId} prop="text" type="string" />
						<BlockProp itemId={itemId} prop="fontSize" type="number" />
						<BlockProp itemId={itemId} prop="color" type="color" /> */}
					</>
				)}
				{type === 'button' && (
					<>
						<ColorField itemId={itemId} prop="color" label="Couleur du texte" />
						<InputField itemId={itemId} prop="text" label="Texte du bouton" />
						<NumberField itemId={itemId} prop="fontSize" label="Taille du texte" />

						<TextField
							id={`${itemId}-onClick-sendTo`}
							select
							label="Choix zone cible"
							value={node.onClick?.sendTo}
							onChange={(e) => nodes.update(itemId, { onClick: { ...node.onClick, sendTo: e.target.value } })}
						>
							<MenuItem value={undefined}></MenuItem>
							{nodes.getNames().map((v, i) => (
								<MenuItem key={i} value={v}>
									{v}
								</MenuItem>
							))}
						</TextField>

						{sendToType === 'web' && (
							<>
								<TextField
									id={`${itemId}-onClick-url`}
									label="URL du bouton"
									value={node.onClick?.url}
									onChange={(e) => nodes.update(itemId, { onClick: { ...node.onClick, url: e.target.value } })}
								/>
								<PropRow>
									<EditorButton
										onClick={() => nodes.update(itemId, { onClick: { ...node.onClick, url: 'back' } })}
										color="secondary"
										startIcon={<BackIcon />}
									>
										Retour
									</EditorButton>
									<EditorButton
										onClick={() => nodes.update(itemId, { onClick: { ...node.onClick, url: 'forward' } })}
										color="secondary"
										startIcon={<ForwardIcon />}
									>
										Avancer
									</EditorButton>
								</PropRow>
							</>
						)}
					</>
				)}
				{type === 'image' && (
					<>
						<FileField itemId={itemId} prop="url" label="URL de l'image" />
					</>
				)}
				{type === 'page' && (
					<>
						<IntervalField itemId={itemId} prop="interval" label="Durée d'affichage (s)" />

						<DateField itemId={itemId} prop="dateStart" label="Date début d'affichage" />
						<DateField itemId={itemId} prop="dateEnd" label="Date fin d'affichage" />

						<TimeField itemId={itemId} prop="timeStart" label="Heure du début d'affichage" />
						<TimeField itemId={itemId} prop="timeEnd" label="Heure de fin d'affichage" />

						<DaysField itemId={itemId} prop="displayDays" label="Jours d'affichage" />
					</>
				)}
			</PropColumn>
			<PropColumn>
				<EditorButton onClick={handleRemove} color="secondary" startIcon={<DeleteIcon />}>
					Supprimer {typeLabel}
				</EditorButton>
				<EditorButton onClick={handleDuplicate} color="secondary" startIcon={<DuplicateIcon />}>
					Dupliquer {typeLabel}
				</EditorButton>
				{itemId !== pageId && (
					<PropRow>
						<EditorButton onClick={handleAdd} startIcon={<AddIcon />}>
							Ajouter dans {typeLabel}
						</EditorButton>
					</PropRow>
				)}
				{!isAdvances && (
					<PropRow>
						<EditorButton onClick={handleAdvances} startIcon={<CopyIcon />}>
							Paramètres avancés
						</EditorButton>
					</PropRow>
				)}
				{isAdvances && (
					<>
						<PropRow>
							<EditorButton onClick={handleCopy} startIcon={<CopyIcon />}>
								Copier {typeLabel}
							</EditorButton>
							<EditorButton onClick={handlePaste} startIcon={<PasteIcon />}>
								Coller {typeLabel}
							</EditorButton>
						</PropRow>
						<PropRow>
							<EditorButton onClick={handleDown} startIcon={<FlipToFrontIcon />}>
								Mettre en avant
							</EditorButton>
							<EditorButton onClick={handleUp} startIcon={<FlipToBackIcon />}>
								Mettre en arrière
							</EditorButton>
						</PropRow>
					</>
				)}
			</PropColumn>

			{isAdvances && (
				<PropColumn>
					<InputField itemId={itemId} prop="class" label="Classe" />
				</PropColumn>
			)}

			{isAdvances && (
				<PropColumn>
					<div className={classes.legend}>{tr('legend_position')}</div>
					{(parentLayout === 'column' || parentLayout === 'row') && (
						<>
							<NumberField itemId={itemId} prop="flex" label="Flex" />
							<PropRow>
								<NumberField itemId={itemId} prop="w" label="Largeur (%)" />
								<NumberField itemId={itemId} prop="h" label="Hauteur (%)" />
							</PropRow>
						</>
					)}
					{parentLayout === 'absolute' && (
						<>
							<PropRow>
								<NumberField itemId={itemId} prop="x" label="Gauche (%)" />
								<NumberField itemId={itemId} prop="y" label="Haut (%)" />
							</PropRow>
							<PropRow>
								<NumberField itemId={itemId} prop="w" label="Largeur (%)" />
								<NumberField itemId={itemId} prop="h" label="Hauteur (%)" />
							</PropRow>
						</>
					)}
				</PropColumn>
			)}

			{isAdvances && (
				<PropColumn>
					<div className={classes.legend}>{tr('legend_layout')}</div>
					<SelectField label="layout" itemId={itemId} prop="layout" options={layoutOptions} />
					{(layout === 'column' || layout === 'row') && (
						<>
							<SelectField
								label="justifyContent"
								itemId={itemId}
								prop="justifyContent"
								options={justifyContentOptions}
							/>
							<SelectField label="alignItems" itemId={itemId} prop="alignItems" options={alignItemsOptions} />
							<SwitchField label="flexReverse" itemId={itemId} prop="flexReverse" />
							<SelectField label="flexWrap" itemId={itemId} prop="flexWrap" options={flexWrapOptions} />
							<SelectField label="alignContent" itemId={itemId} prop="alignContent" options={alignContentOptions} />
							<SwitchField label="flexInline" itemId={itemId} prop="flexInline" />
						</>
					)}
				</PropColumn>
			)}

			{isAdvances && (
				<PropColumn>
					<HtmlEditor itemId={itemId} />
				</PropColumn>
			)}

			{type === 'root' && isAdvances && (
				<PropColumn>
					<TextEditor title="css" prop="css" itemId={itemId} />
				</PropColumn>
			)}

			{isAdvances && (
				<PropColumn>
					<JsonEditor itemId={itemId} />
				</PropColumn>
			)}
		</div>
	);
}

export default function EditorProperties() {
	const classes = useStyles();
	const selectedId = useObservable(selectedId$);
	return selectedId ? <EditorPropertiesWithItem itemId={selectedId} /> : <div className={classes.root} />;
}
