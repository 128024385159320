import { useState, useEffect } from 'react';

export default function useTimer(intervalMs: number) {
	const [t, setT] = useState(0);

	useEffect(() => {
		const i = setInterval(() => setT((t) => t + 1), intervalMs);
		return () => clearInterval(i);
	}, [intervalMs]);

	return t;
}
