const _scriptFileMap: Record<string, HTMLElement> = {};
(window as any)._scriptFileMap = _scriptFileMap;

export default function addScriptFile(url: string) {
    return new Promise((resolve) => {
        if (_scriptFileMap[url]) return resolve(true);
        const el = document.createElement('script');
        console.debug('addScriptFile', url, el);
        el.type = 'text/javascript';
        el.async = true;
        el.onerror = (err) => {
            console.error('addScriptFile onerror', url, err)
            resolve(false);
        };
        el.onload = () => {
            console.debug('addScriptFile onload', url)
            resolve(true);
        };
        el.src = url;
        document.head.appendChild(el);
        _scriptFileMap[url] = el;
    });
}