import { BehaviorSubject } from 'rxjs';
import useObservable from './useObservable';

export const urlParams$ = new BehaviorSubject(readUrlParams());

window.addEventListener('popstate', () => {
	const urlParams = readUrlParams();
	console.info('handlePopState', { urlParams });
	urlParams$.next(urlParams);
});

export function updateUrlParams(update: Record<string, string>) {
	const queryParams = new URLSearchParams(window.location.search);
	for (const prop in update) queryParams.set(prop, update[prop]);
	window.history.pushState(null, '', `?${queryParams}`);
	const urlParams = readUrlParams();
	console.info('updateUrlParams', { urlParams });
	urlParams$.next(urlParams);
}

export function readUrlParams() {
	var match,
		pl = /\+/g, // Regex for replacing addition symbol with a space
		search = /([^&=]+)=?([^&]*)/g,
		decode = (s: any) => decodeURIComponent(s.replace(pl, ' ')),
		query = window.location.search.substring(1);
	const urlParams: Record<string, string> = {};
	while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);
	return urlParams;
}

export default function useUrlParams() {
	return useObservable(urlParams$);
}
