import * as React from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import useNode from '~src/hooks/useNode';
import useDownload from '~src/hooks/useDownload';
import { getHandleBlockEvent } from '~src/hooks/useBlockEventListener';
import addCss from '~src/helpers/addCss';

addCss('BlockVideo', `
.b--video { }
.b__video-player { margin: 0; width: 100%; height: 100%; background-repeat: no-repeat; background-size: contain; background-position: center; }`);

export default function BlockVideo(props: BlockProps) {
	const data = useNode(props.itemId);
	const isWebm = !!data.webmUrl;
	const isMp4 = !isWebm && !!data.mp4Url;
	const videoUrl = isWebm ? data.webmUrl : isMp4 ? data.mp4Url : data.url;
	const videoType = isWebm ? "video/webm" : isMp4 ? "video/mp4" : undefined;
	const videoFile = useDownload(videoUrl);
	const coverFile = useDownload(data.coverUrl);

	console.debug('BlockVideo', {
		data,
		isWebm,
		isMp4,
		videoUrl,
		videoType,
		videoFile,
		coverFile,
	});

	return (
		<Block {...props}>
			<video
				key={data.id}
				className="b__video-player"
				style={{
					backgroundImage: coverFile ? `url(${coverFile.blobUrl})` : undefined,
				}}
				loop
				autoPlay
				playsInline
				controls={false}
				onEnded={getHandleBlockEvent(props.itemId, ['ended'])}
			>
				{videoFile?.blobUrl && <source src={videoFile.blobUrl} type={videoType}></source>}
				Désolé, votre appareil ne prend pas en charge le format de la vidéo.
			</video>
		</Block>
	);
}