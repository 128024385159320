import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import nodes from '~src/controllers/NodesController';
import useConstant from '~src/hooks/useConstant';
import { ViewportInfo } from './ViewportInfo';
import { nbr } from '~src/helpers/converters';
import useNode from '~src/hooks/useNode';
import useObservable from '~src/hooks/useObservable';
import selectedId$ from '~src/messages/selectedId$';
import useNodeEl from '~src/hooks/useNodeEl';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		boxSizing: 'border-box',
		userSelect: 'none',
		pointerEvents: 'none',

		'& span': {
			position: 'absolute',
			// border: `2px solid ${theme.palette.secondary.main}`,
			boxSizing: 'border-box',
			width: 14,
			height: 14,
			margin: -7,
			background: 'white',
			userSelect: 'none',
			pointerEvents: 'all',
		},
	},
	border: {
		border: '2px solid #f60356',
		position: 'absolute',
		boxShadow: '2px 4px 10px 2px rgba(0,0,0,0.5)',
		userSelect: 'none',
		pointerEvents: 'none',
		top: -1,
		left: -1,
		right: -1,
		bottom: -1,
	},
	tl: { cursor: 'nwse-resize', top: 0, left: 0 },
	tr: { cursor: 'nesw-resize', top: 0, right: 0 },
	bl: { cursor: 'nesw-resize', bottom: 0, left: 0 },
	br: { cursor: 'nwse-resize', bottom: 0, right: 0 },
	cr: { cursor: 'ew-resize', top: '50%', right: 0 },
	cl: { cursor: 'ew-resize', top: '50%', left: 0 },
	tc: { cursor: 'ns-resize', top: 0, left: '50%' },
	bc: { cursor: 'ns-resize', bottom: 0, left: '50%' },
	cc: { cursor: 'move', top: '50%', left: '50%', borderRadius: '50%' },
}));

export default function EditorSelected({ viewportInfo }: { viewportInfo: ViewportInfo }) {
	const classes = useStyles();
	// const viewport = viewportInfo?.viewport;
	const info = useConstant<{ isDrag: boolean, refreshTimer: any }>(() => ({ isDrag: false, refreshTimer: null }));
	const [x, setX] = useState(Number.NaN);
	const [y, setY] = useState(Number.NaN);
	const [w, setW] = useState(Number.NaN);
	const [h, setH] = useState(Number.NaN);
	const selectedId = useObservable(selectedId$);
	const selectedEl = useNodeEl(selectedId);
	const selected = useNode(selectedId);
	const parentId = selected.parentId;
	const parentEl = useNodeEl(parentId) || viewportInfo?.viewportEl;
	const parent = useNode(parentId);

	console.debug('EditorSelected', { selected, parent, parentEl, selectedEl });

	useEffect(() => {
		const refresh = () => {
			if (!selectedId$.value) return;
			if (selectedEl && !info.isDrag) {
				const r = selectedEl && selectedEl.getBoundingClientRect();
				setX(r.x);
				setY(r.y);
				setW(r.width);
				setH(r.height);
			}
		};
		// refresh();
		clearTimeout(info.refreshTimer);
		info.refreshTimer = setTimeout(refresh, 50);
	}, [selectedId, parentId, selected, parent, viewportInfo, info, selectedEl, parentEl]);

	const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const el = e.currentTarget as HTMLDivElement;
		const cornerByClass = {
			[classes.tl]: 'tl',
			[classes.tr]: 'tr',
			[classes.bl]: 'bl',
			[classes.br]: 'br',
			[classes.cr]: 'cr',
			[classes.cl]: 'cl',
			[classes.tc]: 'tc',
			[classes.bc]: 'bc',
			[classes.cc]: 'cc',
		};
		const corner = el?.className
			?.split(' ')
			.map((c) => cornerByClass[c])
			.find((c) => c);

		console.debug('EditorSelected onMouseDown corner', corner);

		if (!corner) return;
		if (!parentEl) return;
		info.isDrag = true;
		const initEvent = { clientX: e.clientX, clientY: e.clientY };
		let [xt, yt, wt, ht] = [x, y, w, h];
		const [x0, y0, w0, h0] = [x, y, w, h];
		const pRect = parentEl.getBoundingClientRect();
		const vRect = viewportInfo?.viewportRect;

		console.debug('EditorSelected onMouseDown pRect', pRect);
		console.debug('EditorSelected onMouseDown vRect', vRect);

		const toRect = (r: DOMRect) => {
			xt = (xt - r.x) / r.width;
			yt = (yt - r.y) / r.height;
			wt = wt / r.width;
			ht = ht / r.height;
		};

		const fromRect = (r: DOMRect) => {
			xt = xt * r.width + r.x;
			yt = yt * r.height + r.y;
			wt = wt * r.width;
			ht = ht * r.height;
		};

		const borne = (v: number, min: number, max: number) => (v < min ? min : v > max ? max : v);

		const onMouseMove = (e: MouseEvent) => {
			const dx = e.clientX - initEvent.clientX;
			const dy = e.clientY - initEvent.clientY;

			if (corner[1] === 'l') {
				const dxb = borne(x0 + dx, vRect.left, x0 + w0) - x0;
				xt = x0 + dxb;
				wt = w0 - dxb;
			}
			if (corner[1] === 'r') {
				wt = borne(w0 + dx, 0, vRect.right - xt);
			}
			if (corner[0] === 't') {
				const dyb = borne(y0 + dy, vRect.top, y0 + h0) - y0;
				yt = y0 + dyb;
				ht = h0 - dyb;
			}
			if (corner[0] === 'b') {
				ht = borne(h0 + dy, 0, vRect.bottom - yt);
			}
			if (corner === 'cc') {
				xt = borne(x0 + dx, vRect.left, vRect.right - wt);
				yt = borne(y0 + dy, vRect.top, vRect.bottom - ht);
			}

			console.debug('global', { xt, yt, wt, ht });
			toRect(vRect);
			console.debug('viewport', { xt, yt, wt, ht });

			xt = Math.round(xt * 96) / 96;
			yt = Math.round(yt * 96) / 96;
			wt = Math.round(wt * 96) / 96;
			ht = Math.round(ht * 96) / 96;

			fromRect(vRect);

			setX(xt);
			setY(yt);
			setW(wt);
			setH(ht);
		};

		const onMouseUp = () => {
			console.debug('EditorSelected onMouseUp', { xt, yt, wt, ht });
			info.isDrag = false;

			toRect(pRect);

			console.debug('EditorSelected onMouseUp toRect', { xt, yt, wt, ht });

			const x = nbr(Math.round(10000 * xt) / 100, 0);
			const y = nbr(Math.round(10000 * yt) / 100, 0);
			const w = nbr(Math.round(10000 * wt) / 100, 100);
			const h = nbr(Math.round(10000 * ht) / 100, 100);

			console.debug('EditorSelected onMouseUp updateData', { x, y, w, h });

			if (selectedId) {
				const type = nodes.get(selectedId).type;
				if (type === 'page' || type === 'root') {
					nodes.update(selectedId, { x: undefined, y: undefined, w: undefined, h: undefined });
				} else {
					nodes.update(selectedId, { x, y, w, h });
				}
			}

			document.removeEventListener('mouseup', onMouseUp, true);
			document.removeEventListener('mousemove', onMouseMove, true);
		};

		document.addEventListener('mouseup', onMouseUp, true);
		document.addEventListener('mousemove', onMouseMove, true);
	};

	const containerRect = viewportInfo?.containerRect || { top:0 , left:0 };
	return (
		<div className={classes.root} style={{
			display: viewportInfo && selectedId && selectedId !== '__root' ? 'block' : 'none',
			top: Number.isNaN(y) ? 0 : y - containerRect.top,
			left: Number.isNaN(x) ? 0 : x - containerRect.left,
			width: Number.isNaN(w) ? 0 : w,
			height: Number.isNaN(h) ? 0 : h
		}}>
			<div className={classes.border} />
			<span className={classes.cc} onMouseDown={onMouseDown} />
			<span className={classes.tl} onMouseDown={onMouseDown} />
			<span className={classes.tr} onMouseDown={onMouseDown} />
			<span className={classes.bl} onMouseDown={onMouseDown} />
			<span className={classes.br} onMouseDown={onMouseDown} />
			<span className={classes.cr} onMouseDown={onMouseDown} />
			<span className={classes.cl} onMouseDown={onMouseDown} />
			<span className={classes.tc} onMouseDown={onMouseDown} />
			<span className={classes.bc} onMouseDown={onMouseDown} />
		</div>
	);
}
