import global from '../global';
import fullyBridge from './fullyBridge';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { serverTimestamp } from 'firebase/database';
import { isEqual } from 'lodash';
import addCss from '../helpers/addCss';
import deviceId$ from '../messages/deviceId$';
import viewerInfo$, { addViewerInfo } from '../messages/viewerInfo$';
import auth from '../services/auth';
import { dbGet, dbObserveAny, DeviceInvoke, dbUpdate, dbDeviceInvokePath, dbUpdateDevice, dbGetDevice, dbUpdateDeviceOnDisconnect, dbConnected$, DeviceDoc } from '../services/db';
import { saveScreenshot } from '../services/storage';
import { Bridge, BridgeNative } from './interfaces';
import pageMode$, { PageMode } from '~src/messages/pageMode$';

console.debug('initDevice');

const native: BridgeNative|undefined = fullyBridge;
const evalContext: any = {
    dbGet,
    dbObserveAny,
    dbUpdate,
    auth,
    addCss,
    deviceId$,
    viewerInfo$,
    isEqual,
    serverTimestamp,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    global,
    dbUpdateDevice,
    dbGetDevice,
    dbConnected$,
    takeScreenshot,
};
let methods: string[] = [];
let storage: Record<string, any> = {};
let storageSaveTimer: any;
let deviceId = '';
// let devicePath = '';

function rand14() {
    var n = crypto ? crypto.getRandomValues(new Uint16Array(4)) : [Math.random(),Math.random(),Math.random(),Math.random()].map(n => Math.round(n*100000));
    return (n[0].toString(32) + n[1].toString(32) + n[2].toString(32) + n[3].toString(32)).substring(0, 14).padEnd(14, '0');
}

async function invoke(method: string, ...args: any[]) {
    const m = (evalContext as any)[method];
    if (!m) throw new Error(`method "${method}" not implemented 2`);
    const res = await m(...(args || []));
    return res;
}

async function evalScript(script: string, ...args: any[]) {
    evalContext.args = args;
    return await (function () {
        return global['eval']('var ctx=this, args=ctx.args;' + script);
    }).call(evalContext);
}

async function takeScreenshot() {
    if (!native) throw new Error('no native');
    const screenshotBase64 = await native.getScreenshotPngBase64();
    const screenshotUrl = await saveScreenshot(deviceId, screenshotBase64);
    console.debug('screenshotUrl', screenshotUrl);
    await dbUpdateDevice(deviceId, { screenshotUrl });
}

async function setKiosk(kiosk: boolean) {
    if (!native) throw new Error('no native');
    await native.setKiosk(kiosk);
    await dbUpdateDevice(deviceId, { kiosk });
}

function getMethods() {
    return methods;
}

async function loadStorage() {
    if (!native) throw new Error('no native');
    const storageJson = await native.loadJsonStorage();
    storage = storageJson ? JSON.parse(storageJson) : {};
    if (typeof storage !== 'object') storage = {};
}

async function saveStorage() {
    if (!native) throw new Error('no native');
    const storageJson = JSON.stringify(storage);
    await native.saveJsonStorage(storageJson);
}

function setStorage(key: string, value: any) {
    storage[key] = JSON.parse(JSON.stringify(value));
    clearTimeout(storageSaveTimer);
    storageSaveTimer = setTimeout(saveStorage, 1000);
}

function getStorage<T=any>(key: string): T {
    return storage[key];
}

async function signUp() {
    console.info('device.signUp');

    addViewerInfo('inscription');

    const email = `native.3_${Date.now().toString(32)}_${rand14()}@boardscreen.fr`;
    const password = '@' + rand14().toUpperCase() + rand14();
    console.info('initDevice signUp user', { email, password });
    const uc = await createUserWithEmailAndPassword(auth, email, password);

    deviceId = uc?.user?.uid;
    if (!deviceId) throw new Error('initDevice signUp !deviceId');
    console.info('initDevice signUp deviceId', deviceId);

    setStorage('email', email);
    setStorage('password', password);

    evalContext.deviceId = deviceId;
}

async function signIn() {
    console.info('device.signIn');

    addViewerInfo('connexion');
    
    const email = getStorage<string>('email');
    const password = getStorage<string>('password');

    if (!email || !password) return await signUp();

    console.info('initDevice signIn');
    console.info('initDevice signIn user', { email, password });
    const uc = await signInWithEmailAndPassword(auth, email, password);

    deviceId = uc?.user?.uid;
    if (!deviceId) throw new Error('initDevice signIn !deviceId');
    console.info('initDevice signIn deviceId', deviceId);
    
    evalContext.deviceId = deviceId;
}

function toNbr<T>(v: any, nanVal: T): number|T {
    const nbr = Number(
      typeof v === "string"
        ? v.replace(/[^0-9,._]/g, "").replace(/,/g, ".")
        : v
    );
    return Number.isNaN(nbr) ? nanVal : nbr;
}

function toHour(v: string) {
    if (!v) return null;
    if (typeof v === 'number') return v;
    if (typeof v !== 'string') return null;
    const p = v.split(':');
    const h = toNbr(p[0], null);
    if (h === null) return null;
    const m = toNbr(p[1], 0) / 60;
    const s = toNbr(p[2], 0) / 3600;
    return h + m + s;
}

function isHourBetween({ val, start, end }: { val: string, start: string, end: string }) {
    const v = toHour(val);
    const f = toHour(start);
    const t = toHour(end);
    if (v === null) throw new Error('val is not hour');
    if (f === null) throw new Error('start is not hour');
    if (t === null) throw new Error('end is not hour');
    if (f < t) return f < v && v < t;
    return f < v || v < t;
}

// function assertHourBetween({ val, start, end, result }: { val: string, start: string, end: string, result: boolean }) {
//     if (isHourBetween({ val, start, end }) === result) {
//         console.debug('assertHourBetween ok', { val, start, end, result });
//     } else {
//         console.error('assertHourBetween ko', { val, start, end, result });
//     }
// }
// assertHourBetween({ val: '03:30', start: '00:30', end: '23:30', result: true});
// assertHourBetween({ val: '12:30', start: '00:30', end: '23:30', result: true});
// assertHourBetween({ val: '22:30', start: '00:30', end: '23:30', result: true});
// assertHourBetween({ val: '03:30', start: '06:30', end: '16:30', result: false});
// assertHourBetween({ val: '12:30', start: '06:30', end: '16:30', result: true});
// assertHourBetween({ val: '22:30', start: '06:30', end: '16:30', result: false});
// assertHourBetween({ val: '03:30', start: '19:30', end: '10:30', result: true});
// assertHourBetween({ val: '12:30', start: '19:30', end: '10:30', result: false});
// assertHourBetween({ val: '22:30', start: '19:30', end: '10:30', result: true});
// assertHourBetween({ val: '02:30', start: '10:30', end: '03:30', result: true});
// assertHourBetween({ val: '08:30', start: '10:30', end: '03:30', result: false});
// assertHourBetween({ val: '12:30', start: '10:30', end: '03:30', result: true});
// assertHourBetween({ val: '23:30', start: '10:30', end: '03:30', result: true});
// assertHourBetween({ val: '24:30', start: '10:30', end: '03:30', result: true});
// assertHourBetween({ val: '12:30', start: '13:30', end: '11:30', result: false});
// assertHourBetween({ val: '03:30', start: '08:30', end: '02:30', result: false});
// assertHourBetween({ val: '05:30', start: '08:30', end: '04:30', result: false});
// assertHourBetween({ val: '12:30', start: '19:30', end: '13:30', result: true});
// assertHourBetween({ val: '23:30', start: '08:30', end: '02:30', result: true});
// assertHourBetween({ val: '01:30', start: '08:30', end: '02:30', result: true});
// assertHourBetween({ val: '02:30', start: '08:30', end: '04:30', result: true});

const screenStartAndEnd = async () => {
    try {
        const deviceData = await dbGetDevice(deviceId);
        if (!deviceData) return;
        await dbUpdateDevice(deviceId, { monitorError: '{}' });
        if (!deviceData.monitorStart) return;
        if (!deviceData.monitorEnd) return;
        const val = toHour(new Date().toLocaleTimeString());
        const start = toHour(deviceData.monitorStart);
        const end = toHour(deviceData.monitorEnd);
        await dbUpdateDevice(deviceId, {
            monitorError: JSON.stringify({ val, start, end })
        });
        console.error('screenStartAndEnd', { val, start, end });
        if (start === null || end === null) return;
        const monitorOn = isHourBetween({ val, start, end });
        native.setScreenOn(monitorOn);
        await dbUpdateDevice(deviceId, { monitorOn });
    } catch(error) {
        console.error('screenStartAndEnd error', error);
        await dbUpdateDevice(deviceId, { monitorError: String(error) });
    }
}

export default async function initDevice() {
    console.info('initDevice');
    try {
        if (!native) {
            if (!pageMode$.value) pageMode$.next(PageMode.Open);
            return;
        } else {
            pageMode$.next(PageMode.View);
        }
        
        const bridge: Bridge = {
            ...native,
            invoke,
            evalScript,
            takeScreenshot,
            setKiosk,
            getMethods,
            loadStorage,
            saveStorage,
            setStorage,
            getStorage,
            signUp,
            signIn,
        };
        Object.assign(evalContext, bridge);
        methods = Object.keys(bridge);

        addCss('device', `html, body { background: white; }`);

        await loadStorage();
        
        addViewerInfo('initialisation de l’appareil');

        const screenSize = await bridge.getScreenSize();
        addViewerInfo(`largeur: ${screenSize.width}`);
        addViewerInfo(`hauteur: ${screenSize.height}`);

        const kiosk = await bridge.getKiosk();
        addViewerInfo(`kiosk: ${kiosk}`);

        const version = '2.0.0';
        addViewerInfo(`version: ${version}`);

        const softInfo = await bridge.getSoftInfo();
        addViewerInfo(`softInfo: ${JSON.stringify(softInfo)}`);

        await signIn(); // get or create device

        addViewerInfo('deviceId: ' + deviceId);

        const deviceData = await dbGetDevice(deviceId);
        evalContext.deviceData = deviceData;

        await dbUpdateDevice(deviceId, {
            id: deviceId,
            screenWidth: screenSize?.width,
            screenHeight: screenSize?.height,
            started: serverTimestamp(),
            updated: serverTimestamp(),
            version,
            softInfo,
            cmd: null,
            invoke: null,
            screenshotUrl: null,
            bridge: true,
            kiosk: true,
        });

        dbConnected$.subscribe(async (connected) => {
            global.fully.setMessageOverlay(connected ? 'connected' : 'disconnected');
            if (connected) {
                dbUpdateDeviceOnDisconnect(deviceId, { disconnected: serverTimestamp(), isConnected: false });
                await dbUpdateDevice(deviceId, { connected: serverTimestamp(), isConnected: true });        
            }
        });

        setTimeout(takeScreenshot, 10000);

        const invokePath = dbDeviceInvokePath(deviceId);
        const invoke$ = dbObserveAny<DeviceInvoke>(invokePath);
        invoke$.subscribe(async (invokeData: DeviceInvoke) => {
            const { method, begin, args } = invokeData;
            if (method && !begin) {
                dbUpdate(invokePath, { begin: serverTimestamp() });
                try {
                    const res = await invoke(method, ...(args || []));
                    const result = res ? JSON.stringify(res) : null;
                    dbUpdate(invokePath, { end: serverTimestamp(), result });
                }
                catch (error) {
                    dbUpdate(invokePath, { end: serverTimestamp(), error: String(error) });
                }
            }
        });

        deviceId$.next(deviceId);
        if (deviceData?.config?.playlist) viewerInfo$.next([]);

        setInterval(screenStartAndEnd, 10000);

        return true;
    }
    catch (error) {
        console.error('initDevice', error);
        addViewerInfo('ERROR : ' + error);
        setTimeout(() => window.location.reload(), 10000);
    }
}