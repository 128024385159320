import { useEffect } from 'react';
import useDbItem from '~src/hooks/useDbItem';
import useObservable from '~src/hooks/useObservable';
import DbPlaylist from '~src/models/DbPlaylist';
import { dbDeviceConfigPlaylistPath } from '~src/services/db';
import clone from '~src/helpers/clone';
import nodes, { ROOT_ID } from '~src/controllers/NodesController';
import React from 'react';
import deviceId$ from '~src/messages/deviceId$';
import BlockFactory from '~src/components/Block/BlockFactory';
import viewerInfo$ from '~src/messages/viewerInfo$';

function ViewerInfo() {
	const viewerInfo = useObservable(viewerInfo$);
	return (
		<pre
			style={{
				background: 'black',
				color: 'green',
				padding: 10,
				position: 'absolute',
				bottom: 0,
				width: '100%',
				minHeight: '100%',
			}}
		>
			{'\nChargement....\n\n' + viewerInfo.join('\n')}
		</pre>
	);
}

export default function ViewerPage() {
	const deviceId = useObservable(deviceId$);
	let playlist = useDbItem<DbPlaylist>(dbDeviceConfigPlaylistPath(deviceId));

	try {
		if (!playlist) {
			const playlistJson = localStorage.getItem('viewer_playlist');
			if (playlistJson) playlist = JSON.parse(playlistJson);
		} else {
			localStorage.setItem('viewer_playlist', JSON.stringify(playlist));
		}
	} catch(err) {
		console.warn('ViewerPage playlist', err);
	}

	console.debug('ViewerPage Render', { deviceId, playlist });

	useEffect(() => {
		nodes.importData(clone(playlist?.rootData || playlist?.root || {}));
	}, [playlist, playlist?.rootData, playlist?.root]);

	if (!playlist) return <ViewerInfo />;
	return <BlockFactory itemId={ROOT_ID} />;
}
