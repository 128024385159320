export function nbr(v: any): number;
export function nbr(v: any, def: number): number;
export function nbr<T>(v: any, def: T): number | T;
export function nbr(v: any, def: any = 0): any {
	return Number.isNaN(typeof v === 'number' ? v : (v = parseFloat(v))) || !Number.isFinite(v) ? def : v;
}

export function str(v: any): string;
export function str(v: any, def: string): string;
export function str<T>(v: any, def: T): string | T;
export function str(v: any, def: any = ''): any {
	return v === null || v === undefined ? def : String(v);
}

export function arr<T = any>(v: any, def: Array<T> = []): Array<T> {
	return v === null || v === undefined ? def : Array.isArray(v) ? v : [v];
}

export function obj<T = any>(v: any, def: T = {} as any): T {
	return typeof v !== 'object' || Array.isArray(v) ? def : v;
}
