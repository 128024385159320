import { useEffect } from 'react';
import action$ from '~src/messages/action$';
import nodes from '~src/controllers/NodesController';
import { NAction } from '~src/models/Node';

export default function useAction(id: string, onAction: (action: NAction) => void) {
	useEffect(() => {
		let lastAction = action$.value;
		const s = action$.subscribe((action) => {
			if (action === lastAction) return;
			lastAction = action;
			if (!action) return;
			if (!action.sendTo) return onAction(action);
			if (action.sendTo === id) return onAction(action);
			if (action.sendTo === nodes.get(id).name) return onAction(action);
		});
		return () => s.unsubscribe();
	});
}