import { BehaviorSubject } from 'rxjs';
import Node, { NData, NExport, NMap, WNode, WNExport, WNMap } from '~src/models/Node';
import clipboard from '~src/helpers/clipboard';
import uid from '~src/helpers/uid';
import { nbr, str } from '~src/helpers/converters';

export function nodeChangeIds(data: NExport): NExport {
	return {
		...data,
		id: uid(),
		children: data.children ? data.children.map(nodeChangeIds) : undefined,
	};
}

function clean(node: NExport) {
}

function toNMap(data: NExport, parentId?: string, id?: string, nodeMap?: WNMap): WNMap {
	if (!id) id = data.id || uid();
	if (!nodeMap) nodeMap = {};
	if (!id) return nodeMap;
	const children = data.children;
	const node = { ...nodeMap[id], ...data, id } as WNode;
	nodeMap[id] = node;
	clean(node);
	if (parentId) node.parentId = parentId;
	if (children && children.length) {
		const childIds: string[] = [];
		children.forEach((child) => {
			const childId = child.id || uid();
			childIds.push(childId);
			toNMap(child, id, childId, nodeMap);
		});
		node.childIds = childIds;
	}
	return nodeMap;
}

function toNData(nodeMap: NMap, id: string): WNExport {
	const node = nodeMap[id];
	if (!node) {
		console.warn('toNode !node', nodes, id);
		return { id };
	}
	const data = { ...node } as WNExport;
	delete (data as any).parentId;
	delete (data as any).childIds;
	if (node.childIds && node.childIds.length) {
		data.children = node.childIds.map((childId) => toNData(nodeMap, childId));
	}
	return data;
}

export const ROOT_ID = '__ROOT__';
export const NULL_ID = '__NULL__';
export const NULL_NODE: Node = { id: NULL_ID, parentId: '', childIds: [] };

export function isNodePage(node?: Node) {
	return !!(node && node.type === 'page');
}

export function isNodeVisible(node?: Node) {
	return !!(node && !node.hidden);
}

export class NodesController {
	toNMap = toNMap;
	toNData = toNData;

	// readonly action$ = new BehaviorSubject<NAction | undefined>(undefined);

	readonly language$ = new BehaviorSubject<string | undefined>(undefined);
	readonly nodes$: { [id: string]: BehaviorSubject<Node> } = {};
	readonly el$Map: { [id: string]: BehaviorSubject<HTMLElement | undefined> } = {};
	readonly updated$ = new BehaviorSubject(new Date());
	readonly nodeMap: NMap = {};
	private _updatedTimer: any;
	private _updatedIds: { [id: string]: true } = {};

	constructor(root: NData) {
		console.debug('nodes.constructor', root);
		this.importData(root);
	}

	updatedNow() {
		console.debug('nodes.updatedNow', this._updatedIds);
		for (const id in this._updatedIds) {
			const node$ = this.nodes$[id];
			if (!node$) continue;
			const node = this.nodeMap[id];
			if (!node) {
				node$.complete();
				node$.unsubscribe();
				delete this.nodes$[id];
				continue;
			}
			if (node$.value === node) continue;
			node$.next(node || NULL_NODE);
		}
		this._updatedIds = {};
		this.updated$.next(new Date());
	}

	updated(ids?: string | string[]) {
		console.debug('nodes.updated', ids);
		if (ids === undefined) ids = this.getIds();
		if (typeof ids === 'string') this._updatedIds[ids] = true;
		else ids.forEach((id) => (this._updatedIds[id] = true));
		clearTimeout(this._updatedTimer);
		this._updatedTimer = setTimeout(() => this.updatedNow(), 0);
	}

	exportData(id: string = ROOT_ID): WNExport {
		const data = toNData(this.nodeMap, id);
		console.debug('nodes.exportData', id, '->', data);
		return data;
	}

	importData(data: NData, parentId?: string, index?: number) {
		console.debug('nodes.importData', data, parentId, index);
		const parent = parentId ? this.nodeMap[parentId] : undefined;
		if (!parent) {
			console.debug('nodes.importData !parentId');
			const root = { ...data, id: ROOT_ID, t: 'root' };
			this.setNMap(toNMap(root, undefined, ROOT_ID));
			this.updated();
			return root;
		}
		const id = data.id || uid();
		const updateMap = toNMap(data, parentId, id);
		updateMap[parent.id] = { ...parent, childIds: [...(parent.childIds || []), id] };
		console.debug('nodes.importData updateMap', updateMap);
		const nodeMap = { ...this.nodeMap, ...updateMap };
		console.debug('nodes.importData nodeMap', nodeMap);
		this.setNMap(nodeMap);
		this.updated(Object.keys(updateMap));
		if (index) this.setIndex(id, index);
		return nodeMap[id];
	}

	// GETTERS

	getIds() {
		return Object.keys(this.nodeMap);
	}

	getNMap() {
		return this.nodeMap;
	}

	getNodes() {
		return Object.values(this.nodeMap);
	}

	getOrUndefined(id?: string): Node | undefined {
		return id ? this.nodeMap[id] : undefined;
	}

	get(id?: string) {
		return id ? this.nodeMap[id] || NULL_NODE : NULL_NODE;
	}

	getProp(id?: string, prop?: keyof NData, defVal?: any) {
		const value = prop ? this.get(id)[prop] : undefined;
		return value === undefined ? defVal : value;
	}

	getPropString(id?: string, prop?: keyof NData, def?: string): string;
	getPropString<T>(id?: string, prop?: keyof NData, def?: T): string | T;
	getPropString(id?: string, prop?: keyof NData, def: string | undefined = undefined): string | undefined {
		return str(this.getProp(id, prop, def), def);
	}

	getPropNumber(id?: string, prop?: keyof NData, def?: number): number;
	getPropNumber<T>(id?: string, prop?: keyof NData, def?: T): number | T;
	getPropNumber(id?: string, prop?: keyof NData, def: number | undefined = undefined): number | undefined {
		return nbr(this.getProp(id, prop, def), def);
	}

	exists(id?: string) {
		return !!(id && this.nodeMap[id]);
	}

	getData(id?: string) {
		const data = { ...this.get(id), childIds: undefined, parentId: undefined };
		delete data.childIds;
		delete data.parentId;
		return data as NData;
	}

	getRoot() {
		return this.get(ROOT_ID);
	}

	getPages() {
		return nodes.getChildren(ROOT_ID).filter(isNodePage);
	}

	getIndex(id?: string) {
		if (!id) return -1;
		const node = this.nodeMap[id];
		const nodeParent = node && node.parentId ? this.nodeMap[node.parentId] : undefined;
		if (!nodeParent || !nodeParent.childIds) return -1;
		return nodeParent.childIds.indexOf(id);
	}

	getByName(name?: string) {
		return name ? this.getNodes().find((node) => node.name === name) || NULL_NODE : NULL_NODE;
	}

	getNames() {
		return this.getNodes().map((node) => node.name).filter((name) => name) as string[];
	}

	getChildIds(id?: string): readonly string[] {
		return this.get(id).childIds || [];
	}

	getChildren(id?: string) {
		return this.getChildIds(id).map((childId) => this.get(childId));
	}

	getParent(id?: string) {
		return this.get(this.get(id).parentId);
	}

	findParent(id?: string, find?: (node: Node) => boolean) {
		let node = this.get(id);
		if (find) {
			while (node) {
				if (find(node)) return node;
				if (!node.parentId) break;
				node = this.get(node.parentId);
			}
		}
		return NULL_NODE;
	}

	find(predicate: (this: void, node: Node, index: number, nodes: Node[]) => any) {
		return this.getNodes().find(predicate);
	}

	getParentChildIds(id?: string) {
		return this.getParent(id).childIds || [];
	}

	getParentChildren(id?: string) {
		return this.getParentChildIds(id).map((childId) => this.get(childId));
	}

	get$(id?: string) {
		return this.nodes$[id || ''] || (this.nodes$[id || ''] = new BehaviorSubject(this.get(id)));
	}

	root$() {
		return this.get$(ROOT_ID);
	}

	// SETTERS

	setNMap(nodeMap: NMap, history = true) {
		console.debug('nodes.setNMap', { nodeMap, history });
		if (history) this._saveHistory(this.nodeMap, nodeMap);
		(this.nodeMap as NMap) = nodeMap;
	}

	replace(id: string, node: Node, history = true) {
		console.debug('nodes.replace', { id, node, history });
		this.setNMap({ ...this.nodeMap, [id]: node }, history);
		this.updated(id);
	}

	update(id: string, nodeUpdate: Partial<Node>, history = true) {
		console.debug('nodes.update', { id, nodeUpdate, history });
		this.replace(id, { ...this.get(id), ...nodeUpdate, id }, history);
	}

	setProp(id: string, prop: string, value: any, history = true) {
		console.debug('nodes.setProp', { id, prop, value, history });
		this.replace(id, { ...this.get(id), [prop]: value }, history);
	}

	setPropString(id: string, prop: string, val: any, history = true) {
		console.debug('nodes.setPropString', { id, prop, val, history });
		return this.setProp(id, prop, str(val, undefined), history);
	}

	setPropNumber(id: string, prop: string, val: any, history = true) {
		console.debug('nodes.setPropNumber', { id, prop, val, history });
		return this.setProp(id, prop, nbr(val, undefined), history);
	}

	private _histories: NMap[] = [];
	private _historiesIndex = 0;

	private _saveHistory(origin: NMap, target: NMap) {
		console.debug('nodes._saveHistory', origin, target);
		const i = this._historiesIndex;
		this._histories[i] = origin;
		this._histories[i + 1] = target;
		this._setHistoryIndex(i + 1);
	}

	private _setHistoryIndex(i: number) {
		console.debug('nodes._setHistoryIndex', i);
		if (i <= 0) return;
		if (i >= this._histories.length) return;
		const nodeMap = this._histories[i];
		console.debug('nodes._setHistoryIndex nodeMap', i, nodeMap);
		if (!nodeMap) return;
		this._historiesIndex = i;
		(this.nodeMap as NMap) = nodeMap;
		this.updated();
	}

	resetHistory() {
		this._histories = [this.nodeMap, this.nodeMap];
		this._historiesIndex = 1;
	}

	undo() {
		console.debug('nodes.undo');
		this._setHistoryIndex(this._historiesIndex - 1);
	}

	redo() {
		console.debug('nodes.redo');
		this._setHistoryIndex(this._historiesIndex + 1);
	}

	setIndex(id: string, index: number) {
		console.debug('nodes.setIndex', id, index);
		const parent = this.getParent(id);
		const oldIndex = this.getIndex(id);
		if (oldIndex === -1) {
			console.warn('Root.setIndex oldIndex === -1', id, index);
			return;
		}
		const childIds = [...(parent.childIds || [])];
		childIds.splice(oldIndex, 1);
		if (index !== -1) childIds.splice(index, 0, id);
		if (parent && parent.id) this.update(parent.id, { childIds });
	}

	private _remove(id: string, history: boolean, removeIds: string[]) {
		const node = this.get(id);
		if (!node) return;
		if (node.childIds) node.childIds.forEach((childId) => this._remove(childId, history, removeIds));
		this.setIndex(id, -1);
		removeIds.push(id);
	}

	remove(id: string, history = true) {
		console.debug('nodes.remove', id, history);
		const removeIds: string[] = [];
		this._remove(id, history, removeIds);
		const nodeMap = { ...this.nodeMap };
		for (const id of removeIds) delete nodeMap[id];
		this.setNMap(nodeMap, history);
		this.updated(removeIds);
	}

	push(parentId: string, node: Node) {
		console.debug('nodes.push', parentId, node);
		if (node.parentId !== parentId) {
			node = { ...node, parentId: parentId };
		}
		const parent = this.get(parentId);
		const childIds = [...(parent.childIds || []), node.id];
		this.update(parentId, { childIds });
		this.update(node.id, node);
		return node;
	}

	copy(id: string) {
		console.debug('nodes.copy', id);
		const data = this.exportData(id);
		console.debug("nodes.copy data", data);
		clipboard.copyJson(data);
	}

	paste(id: string) {
		console.debug('nodes.paste', id);
		let data = clipboard.pasteJson();
		console.debug("nodes.paste data", data);
		if (Object.keys(toNMap(data)).find(id => this.nodeMap[id])) {
			data = nodeChangeIds(data);
			console.debug("nodes.paste changeIds", data);
		}
	}

	cut(id: string) {
		console.debug('nodes.cut', id);
		this.copy(id);
		this.remove(id);
	}

	duplicate(id: string) {
		console.debug('nodes.duplicate', id);
		const data = nodeChangeIds(this.exportData(id) as NExport);
		const parentId = this.get(id).parentId;
		if (parentId) {
			const index = this.getIndex(id);
			return this.importData(data, parentId, index);
		} else {
			return this.importData(data, id);
		}
	}

	add(id: string, data?: NExport) {
		console.debug('nodes.add');
		const node = this.importData(data || {}, id);
		return node;
	}
}

export const nodes = new NodesController({ id: ROOT_ID });
export default nodes;
(window as any)._nodes = nodes;