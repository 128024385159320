const global = (window as any);
global.globalThis = global;

global.version = '1.2';

console.debug('global:init');

window.onerror = (e) => {
    console.error('window error', e);
};

export default global;