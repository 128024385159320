import React, { useState } from 'react';
import useDbItem from '~src/hooks/useDbItem';
import useObservable from '~src/hooks/useObservable';
import deviceId$ from '~src/messages/deviceId$';
import {
	dbDeviceConfigPlaylistPath,
	dbDeviceInvokePath,
	dbDevicePath,
	dbGet,
	dbSet,
	dbUpdate,
	DeviceDoc,
	DeviceInvoke,
} from '~src/services/db';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import global from '../global';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import useConstant from '~src/hooks/useGlobalState';

function sleep(ms: number) {
	return new Promise((res) => setTimeout(res, ms));
}

async function deviceInvoke(method: string, ...args: any[]) {
	const deviceId = deviceId$.value;
	await dbSet<DeviceInvoke>(dbDeviceInvokePath(deviceId), { method, args });
	for (let i = 0; i < 20; i++) {
		await sleep(i * 100 + 50);
		const invoke = await dbGet<DeviceInvoke>(dbDeviceInvokePath(deviceId));
		if (invoke && invoke.end) {
			console.info('deviceInvoke end', method, invoke);
			global._result = undefined;
			global._error = invoke.error;
			try {
				return global._result = invoke.result ? JSON.parse(invoke.result) : undefined;
			} catch(e) { console.warn('deviceInvoke end', e) }
			return undefined;
		}
	}
}

async function initInvoke() {
	const methods: string[] = await deviceInvoke('getMethods');
	const bridgeMethods: string[] = await deviceInvoke('getBridgeMethods');
	console.info('initInvoke', { methods, bridgeMethods });
	return (global._invoke = {
		...Object.fromEntries(methods.map((m) => [m, (...args: any[]) => deviceInvoke(m, ...args)])),
		...Object.fromEntries(
			bridgeMethods.map((m) => [`_${m}`, (...args: any[]) => deviceInvoke('bridgeInvoke', m, ...args)]),
		),
	});
}

global._deviceInvoke = deviceInvoke;
global._initInvoke = initInvoke;

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		margin: 0,
		width: '100%',
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		overflowY: 'auto',
	},
	console: {
		height: 400,
		margin: 0,
		padding: theme.spacing(1),
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		background: 'black',
		color: '#85ff85',
		overflowY: 'auto',
		'& span': {
			color: '#FFFFFF',
		},
	},
	consoleItems: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		'& input': {
			flex: 1,
			margin: theme.spacing(0.5),
			fontSize: '110%',
		},
		'& button': {
			margin: theme.spacing(0.5),
		},
		'& textarea': {
			flex: 1,
		},
		'& .MuiButton-root': {
			margin: theme.spacing(0.5),
		},
	},
	deviceField: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));

interface DeviceFieldProps {
	label: string;
	device: DeviceDoc | undefined;
	prop: keyof DeviceDoc;
	width: number;
	disabled?: boolean;
	type?: 'text' | 'time';
}
function DeviceField({ label, device, prop, width, disabled, type }: DeviceFieldProps) {
	const classes = useStyles();
	const deviceValue = device && device[prop];
	const [text, setText] = useState('');
	const c = useConstant(() => ({ timer: null as any }));
	if (!device) return null;

	async function handleChange(e: any) {
		if (!device || disabled) return;
		const text = e.target.value;
		setText(text);
		clearTimeout(c.timer);
		c.timer = setTimeout(() => {
			dbSet(dbDevicePath(device.id) + '/' + prop, text)
				.then(() => setText(''))
				.catch((error) => console.error('DeviceField dbSet', error));
		}, 1000);
	}

	return (
		<div className={classes.deviceField}>
			<TextField
				label={label}
				type={type || 'text'}
				value={text || deviceValue}
				size="small"
				style={{ width, margin: 2 }}
				onChange={handleChange}
				disabled={disabled}
				inputProps={type === 'time' ? { step: 300 } : undefined}
			/>
		</div>
	);
}

export default function BridgeConsole() {
	const classes = useStyles();
	const deviceId = useObservable(deviceId$);
	const device = useDbItem<DeviceDoc>(dbDevicePath(deviceId));
	global.device = device;
	const invoke = device?.invoke;

	console.debug('BridgeConsole Render', { device });

	function handlePlaylistCopy() {
		global._playlist = device?.config.playlist;
	}

	function handlePlaylistPaste() {
		dbSet(dbDeviceConfigPlaylistPath(deviceId), global._playlist);
	}

	async function handleReset() {
		await dbUpdate<DeviceDoc>(dbDevicePath(deviceId), {
			monitorStart: '07:00',
			monitorEnd: '19:00',
		});
		deviceInvoke('reset');
	}

	const method = invoke?.method || '';
	const begin = invoke?.begin || 0;
	const end = invoke?.end || 0;
	const result = invoke?.result || null;
	const error = invoke?.error || null;
	const invokeRes =
		begin === 0
			? `${method} (wait)`
			: end === 0
			? `${method} (start)`
			: error
			? `${method} (error) ${error}`
			: `${method} (end:${(end - begin) / 1000}s) ${result}`;

	return (
		<div className={classes.root}>
			<div className={classes.consoleItems}>
				<DeviceField label="Id" device={device} prop="id" width={200} disabled />
				<DeviceField label="Nom" device={device} prop="name" width={200} />
				<DeviceField label="Start" device={device} prop="monitorStart" width={120} type="time" />
				<DeviceField label="End" device={device} prop="monitorEnd" width={120} type="time" />
			</div>
			<div className={classes.consoleItems}>
				<TextareaAutosize value={invokeRes} />
			</div>
			<div className={classes.consoleItems}>
				<Button size="small" variant="contained" color="primary" onClick={handlePlaylistCopy}>
					COPIER PLAYLIST
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={handlePlaylistPaste}>
					COLLER PLAYLIST
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('takeScreenshot')}>
					SCREENSHOT
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('setKiosk', true)}>
					OUVRIR LE KIOSK
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('setKiosk', false)}>
					FERMER LE KIOSK
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('reload', false)}>
					RAFRAICHIR
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('restart', false)}>
					REDÉMARRER
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('exit', false)}>
					QUITTER
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('setScreenOn', true)}>
					ALLUMER
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => deviceInvoke('setScreenOn', false)}>
					ÉTEINDRE
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={handleReset}>
					RESET
				</Button>
				54823
			</div>
		</div>
	);
}
