import * as React from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import { useState, useRef } from 'react';
import useEventListener from '~src/hooks/useEventListener';
import useNode from '~src/hooks/useNode';
import useAction from '~src/hooks/useAction';
import addCss from '~src/helpers/addCss';
import pageMode$, { PageMode } from '~src/messages/pageMode$';
import useObservable from '~src/hooks/useObservable';

addCss('BlockWeb', `
.b__web-view { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.b__web-loading {  }

.b__web-editor {
	font-size: 20px;
	color: black;
	background: white;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
`);

function BlockWebScreenShot(props: BlockProps) {
	const {url} = useNode(props.itemId);
	return (
		<Block {...props}>
			<div className="b__web-editor">
				Page WEB : {url}
			</div>
		</Block>
	);
}

function BlockIFrame(props: BlockProps) {
	const {url} = useNode(props.itemId);
	const ref = useRef<HTMLIFrameElement>(null);
	const [isBusy, setIsBusy] = useState(false);
	const [currentUrl, setCurrentUrl] = useState('');

	useEventListener(ref.current, '', () => setIsBusy(true));
	useEventListener(ref.current, 'load', () => setIsBusy(false));
	useEventListener(ref.current, 'error', () => setIsBusy(false));

	useAction(props.itemId, (action) => {
		if (action.url) {
			console.debug('BlockWebWebView action url', ref.current, action.url);
			switch(action.url) {
				case 'back':
					ref.current?.contentWindow?.history.back();
					break;
				case 'forward':
					ref.current?.contentWindow?.history.forward();
					break;
				default:
					setCurrentUrl(action.url);
					break;
			}
		}
	});

	return (
		<Block {...props}>
			<iframe className="b__web-view" src={currentUrl||url} ref={ref} style={{ border: 'none' }} title={props.itemId} />
			{isBusy && <div className="b__web-loading">Chargement...</div>}
		</Block>
	);
}

function BlockWebWebView(props: BlockProps) {
	const {url} = useNode(props.itemId);
	const ref = useRef<any>();
	const [isBusy, setIsBusy] = useState(false);

	useEventListener(ref.current, 'did-start-loading', () => setIsBusy(true));
	useEventListener(ref.current, 'did-stop-loading', () => setIsBusy(false));

	useAction(props.itemId, (action) => {
		if (action.url) {
			console.debug('BlockWebWebView action url', ref.current, action.url);
			switch(action.url) {
				case 'back':
					ref.current.goBack();
					break;
				case 'forward':
					ref.current.goForward();
					break;
				default:
					ref.current.loadURL(action.url);
					break;
			}
		}
	});

	return (
		<Block {...props}>
			<webview className="b__web-view" {...{ allowtransparency: 'true' }} src={url} ref={ref} />
			{isBusy && <div className="b__web-loading">Chargement...</div>}
		</Block>
	);
}

export default function BlockWeb(props: BlockProps) {
	const pageMode = useObservable(pageMode$);
	const webviewEl = document.createElement('webview');
	const webviewElConstructorName = webviewEl?.constructor?.name;
	const hasWebView = webviewElConstructorName === 'WebViewElement';
	console.debug('hasWebView', webviewElConstructorName, hasWebView);
	if (hasWebView) return <BlockWebWebView {...props} />;
	if (pageMode === PageMode.Edit) return <BlockWebScreenShot {...props} />;
	return <BlockIFrame {...props} />;
}
