import { BehaviorSubject } from 'rxjs';
import { getDownloadURL, getStorage, listAll, ref, StorageReference, uploadBytesResumable, deleteObject, uploadString } from 'firebase/storage';
import firebaseApp from './firebaseApp';
import auth from './auth';

const storage = getStorage(firebaseApp);

export const storageTransferred$ = new BehaviorSubject(1);

function splitFileExt(name: string) {
	const i = name.lastIndexOf('.');
	return i === -1 ? { name, ext: '' } : { name: name.substr(0, i), ext: name.substr(i) };
}

export function storageList(dirPath: string) {
	return listAll(ref(storage, dirPath))
}

export function storageDelete(item: StorageReference) {
	return deleteObject(item);
}

export function storageUpload(file: File, getRef?: (ref: StorageReference) => void) {
	const date = new Date().toJSON().replace(/[-T:]/g, '').substring(0, 14);
	const { name, ext } = splitFileExt(file.name);
	const sName = name.toLowerCase().replace(/[^a-z0-9]/g, '');
	if (!auth.currentUser || !auth.currentUser.uid) return Promise.resolve('');
	const path = `/medias/${auth.currentUser.uid}/${date}_${sName}${ext}`;
	console.debug('Storage.putFile', file, path);
	const fileRef = ref(storage, path);
	if (getRef) getRef(fileRef);
	return new Promise<string>((resolve, reject) => {
		const uploadTask = uploadBytesResumable(fileRef, file);
		uploadTask.on(
			'state_changed',
			(snapShot) => {
				console.debug('Storage.putFile snapShot', snapShot);
				storageTransferred$.next(snapShot.bytesTransferred / snapShot.totalBytes);
			},
			(err) => {
				console.debug('Storage.putFile error', err);
				reject(err);
			},
			() => {
				console.debug('Storage.putFile complete');
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.debug('Storage.putFile url', downloadURL);
					resolve(downloadURL);
				});
			},
		);
	});
}

export async function saveScreenshot(deviceId: string, base64: string) {
	console.debug('saveScreenshot', deviceId, base64);
	const path = `/medias/${deviceId}/screenshot.jpg`;
	console.debug('saveScreenshot path', path);
	const imageRef = ref(storage, path);
	const res = await uploadString(imageRef, base64, 'base64', { contentType: 'image/jpg' });
	console.debug('saveScreenshot res', res);
	const url = await getDownloadURL(imageRef);
	console.debug('saveScreenshot url', url);
	return url;
}

export async function storageGetUrl(gs: string) {
	console.debug('storageGetUrl', gs);
	return await getDownloadURL(ref(storage, gs));
}