import '~public/index.css';
import initDevice from '~src/bridge/initDevice';
import ReactDOM from 'react-dom';
import App from '~src/components/App';
import blockRegisters from '~src/components/Block/blockRegisters';

console.debug('index');

initDevice();

blockRegisters();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// window.addEventListener('load', () => {
//     if ("serviceWorker" in navigator) {
//         navigator.serviceWorker.register('/sw.js');
//     }
// });

// const stopServiceWorker = localStorage.getItem('stopServiceWorker');
// stopServiceWorker ? serviceWorker.unregister() : serviceWorker.register();
