export function logP<T>(getPromise: () => Promise<T>) {
	return getPromise()
		.then((value) => {
			console.debug('promise success', getPromise.toString(), value);
			return value;
		})
		.catch((error) => {
			console.error('promise error', getPromise.toString(), error);
			throw error;
		});
}
