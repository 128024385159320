import { BehaviorSubject } from 'rxjs';
import nodes from '~src/controllers/NodesController';
import { getIcon } from '~src/components/Editor/Icon';
import { NType } from '~src/models/Node';

const typeInfoMap$ = new BehaviorSubject<Record<NType, { title: string, label: string, icon?: any, isAdvanced?: boolean }>>({
    [NType.None]: { title: "Élément", label: "L’élément", icon: getIcon('widgets') },
    [NType.Block]: { title: "Élément", label: "L’élément", icon: getIcon('widgets') },
    [NType.Web]: { title: "Page Web", label: "La page web", icon: getIcon('public') },
    [NType.Pdf]: { title: "PDF", label: "Le pdf", icon: getIcon('picture_as_pdf') },
    [NType.Video]: { title: "Vidéo", label: "La video", icon: getIcon('smart_display') },
    [NType.Youtube]: { title: "Youtube", label: "YouTube", icon: getIcon('live_tv') },
    [NType.Image]: { title: "Image", label: "L’image", icon: getIcon('image') },
    [NType.Text]: { title: "Texte", label: "Le text", icon: getIcon('text_format') },
    [NType.Button]: { title: "Bouton", label: "Le bouton", icon: getIcon('smart_button'), isAdvanced: true },
    [NType.Carousel]: { title: "Carrousel", label: "Le carousel", icon: getIcon('view_carousel'), isAdvanced: true },
    [NType.Root]: { title: "Écran", label: "L’écran", icon: getIcon('home'), isAdvanced: true },
    [NType.Page]: { title: "Page", label: "La page", icon: getIcon('article'), isAdvanced: true },
    // html: { title: "Texte", icon: getIcon('text_format') },
    // row: { title: "Ligne", icon: getIcon('view_list') },
    // column: { title: "Colonne", icon: getIcon('view_column') },
    // center: { title: "Centre", icon: getIcon('vertical_align_center') },
    // translate: { title: "Traduction", icon: getIcon('translate'), isAdvanced: true },
    // template: { title: "Modèle", icon: getIcon('code'), isAdvanced: true },
});

nodes.updated$.subscribe(() => {
    let typeInfoMap = typeInfoMap$.value;
    nodes.getNodes().forEach(node => {
        // const type = node.type || '';
        // if (type === 'template') {
        //     const { id, title, icon } = node;
        //     if (!key) return;
        //     const typeInfo = typeInfoMap[key];
        //     if (!typeInfo || typeInfo.title !== (title||key)) {
        //         typeInfoMap = {
        //             ...typeInfoMap,
        //             [key]: {
        //                 title: (title||key),
        //                 icon: icon && getIcon(icon),
        //             }
        //         };
        //     }
        // }
    });
    if (typeInfoMap$.value !== typeInfoMap) {
        typeInfoMap$.next(typeInfoMap);
    }
});

export default typeInfoMap$;