import * as React from 'react';
import { useState, useEffect } from 'react';
import BlockProps from './BlockProps';
import Block from './Block';
import useNode from '~src/hooks/useNode';
import addCss from '~src/helpers/addCss';
import pdf from '~src/services/PdfService';
import useDownload from '~src/hooks/useDownload';

addCss(
	'BlockPdf',
	`
.b--pdf {}
.b__pdf-page {
	position: absolute;
	width: 100%;
	height: 100%;
	transition: transform 0.4s ease, opacity 0.4s ease;
	background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
	visibility: hidden;
	transform: translateX(100%);
	opacity: 0;
}
.b__pdf-page--active { visibility: visible; transform: translateX(0); opacity: 1; }
.b__pdf-page--after { visibility: visible; transform: translateX(-100%); opacity: 0; }
`,
);

const CLS_PAGE = 'b__pdf-page';
const CLS_PAGE_ACTIVE = 'b__pdf-page b__pdf-page--active';
const CLS_PAGE_AFTER = 'b__pdf-page b__pdf-page--after';

function PdfPage({ active, pageUrl }: { active: boolean; pageUrl: string }) {
	const [cls, setCls] = useState(CLS_PAGE);

	useEffect(() => {
		let timer: any;
		if (active) {
			setCls(CLS_PAGE_ACTIVE);
		} else {
			setCls(CLS_PAGE_AFTER);
			timer = setTimeout(() => setCls(CLS_PAGE), 500);
		}
		return () => {
			clearTimeout(timer);
		}
	}, [active]);

	return <div className={cls} style={{ backgroundImage: `url('${pageUrl}')` }} />;
}

export default function BlockPdf(props: BlockProps) {
	const data = useNode(props.itemId);
	const file = useDownload(data.url);
	const interval = data.interval || 5000;
	const [pageUrls, setPageUrls] = useState<string[]>([]);
	const [count, setCount] = useState(0);

	const activeIndex = count % pageUrls.length;

	useEffect(() => {
		console.debug('BlockPdf url', file.blobUrl);
		setPageUrls([]);
		setCount(0);
		if (file.blobUrl) {
			pdf.getPageUrls(file.blobUrl).then((pageUrls) => setPageUrls(pageUrls));
		}
	}, [file.blobUrl]);

	useEffect(() => {
		const timer = setInterval(() => setCount((i) => i + 1), interval);
		return () => clearInterval(timer);
	}, [interval]);

	return (
		<Block {...props}>
			{pageUrls.map((pageUrl, pageIndex) => (
				<PdfPage key={pageIndex} active={pageIndex === activeIndex} pageUrl={pageUrl} />
			))}
		</Block>
	);
}