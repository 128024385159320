import addScriptFile from '~src/helpers/addScriptFile';

const w = window as any;

addScriptFile('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.min.js').then(() => {
    console.debug('pdfjsLib', w.pdfjsLib);
    w.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.min.js';
});

export class PdfService {
    cache: { [url: string]: Promise<string[]> } = {};

    private _getDocument(url: string) {
        console.debug('PdfService._getDocument', url);
        return new Promise<any>((resolve) => {
            if (!url) {
                return resolve(null);
            }
            const loadingTask = w.pdfjsLib.getDocument(url);
            // loadingTask.onProgress((processData: any) => {
            //     console.debug('pdfjsLib.getDocument processData', url, processData); //, processData?.loaded / processData?.total);
            // });
            loadingTask.promise.then(resolve, (rejectReason: any) => {
                if (rejectReason) {
                    console.error('pdfjsLib.getDocument', url, rejectReason);
                    resolve(null);
                }
            });
        });
    }

    private _getPageDataURL(url: string, pdfDoc: any, pageNumber: number) {
        console.debug('PdfService._getPageDataURL', url, pdfDoc, pageNumber);
        return new Promise<string|null>((resolve) => {
            pdfDoc.getPage(pageNumber).then((pdfPage: any) => {
                const viewport = pdfPage.getViewport({ scale: 4 });
                const canvas = document.createElement('canvas');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const canvasContext = canvas.getContext('2d');
                if (!canvasContext) return resolve(null);
                pdfPage.render({ canvasContext, viewport }).promise.then(() => {
                    canvas.toBlob(blob => {
                        const blobUrl = blob ? URL.createObjectURL(blob) : null;
                        if (!blobUrl) throw new Error('no blob url');
                        console.debug('pdf._getPageDataURL render', url, blobUrl, pageNumber);
                        resolve(blobUrl);
                    });
                }, (rejectReason: any) => {
                    console.error('pdf._getPageDataURL render', url, pageNumber, rejectReason);
                    resolve(null);
                });
            }, (rejectReason: any) => {
                console.error('pdf._getPageDataURL getPage', url, pageNumber, rejectReason);
                resolve(null);
            });
        });
    }

    private _getPageUrls(url: string) {
        return this._getDocument(url)
            .then(pdfDoc => {
                if (!pdfDoc) return Promise.resolve([]);
                const pageNumbers = Array(pdfDoc.numPages).fill(0).map((_,i) => i+1);
                const pagePromises = pageNumbers.map(pageNumber => this._getPageDataURL(url, pdfDoc, pageNumber));
                return Promise.all(pagePromises);
            })
            .then(urls => urls.filter(url => url) as string[]);
    }

    getPageUrls(url: string) {
		if (!url) return Promise.resolve([]);
        return this.cache[url] || (this.cache[url] = this._getPageUrls(url));
    }
}

export const pdf = new PdfService();
export default pdf;
(window as any)._pdf = pdf;