import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import BlockFactory from '~src/components/Block/BlockFactory';
import EditorSelected from './EditorSelected';
import Icon from '@mui/material/Icon';
import Fab from '@mui/material/Fab';
import { useTr } from '~src/services/tr';
import useConstant from '~src/hooks/useConstant';
import { ViewportInfo } from './ViewportInfo';
import nodes from '~src/controllers/NodesController';
import SaveIcon from '@mui/icons-material/Save';
import BackIcon from '@mui/icons-material/ArrowBack';
import { setSelectedId } from '~src/messages/selectedId$';
import { nodeElToNode } from '~src/messages/nodeEl$';
import deviceId$ from '~src/messages/deviceId$';
import useObservable from '~src/hooks/useObservable';
import viewport$ from '~src/messages/viewport$';
import { dbGet, dbDeviceConfigPlaylistPath, dbUpdate, dbDeviceConfigPath } from '~src/services/db';
import clone from '~src/helpers/clone';
import pageMode$, { PageMode } from '~src/messages/pageMode$';
import DbPlaylist from '~src/models/DbPlaylist';
import { updateUrlParams } from '~src/hooks/useUrlParams';
import { nbr } from '~src/helpers/converters';
import useWindowSize from '~src/hooks/useWindowSize';
import getKioskUrl from './getKioskUrl';
import { serverTimestamp } from 'firebase/database';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		background: '#e2e2e2',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	topbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& input': {
			width: 50,
			margin: 2,
			textAlign: 'center',
		},
		padding: '0 5px',
		zIndex: 10,
	},
	topbarDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		margin: 3,

		'& .MuiInput-root': {
			width: 200,
		},
	},
	topbarFab: {
		margin: 3,
	},
	container: {
		flex: 1,
		position: 'relative',
		overflow: 'hidden',
	},
	view: {
		position: 'absolute',
		overflow: 'hidden',
		background: '#FFFFFF',
	},
	fabActions: {
		position: 'absolute',
		right: 0,
		bottom: 0,
		'& .MuiFab-root': {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
}));

export default function EditorViewport() {
	const classes = useStyles();
	const viewport = useObservable(viewport$);
	const viewportRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const containerEl = containerRef.current;
	const [info, setInfo] = useState<ViewportInfo | null>(null);
	const infoConstant = useConstant(() => ({ info }));
	const [zoom, setZoom] = useState(0);
	const windowSize = useWindowSize();
	const hasZoom = zoom !== 0;

	infoConstant.info = info;

	useEffect(() => {
		const viewportEl = viewportRef.current;
		const containerEl = containerRef.current;
		if (!viewportEl) return;
		if (!containerEl) return;

		const handleEvent = () => {
			const viewportRect = viewportEl.getBoundingClientRect();
			const containerRect = containerEl.getBoundingClientRect();
			const nextInfo: Partial<ViewportInfo> = {
				viewport,
				viewportRect,
				containerRect,
				rect: {
					top: viewportRect.top - containerRect.top,
					left: viewportRect.left - containerRect.left,
					width: viewportRect.width,
					height: viewportRect.height,
				},
			};
			nextInfo.json = JSON.stringify(nextInfo);
			if (infoConstant.info?.json === nextInfo.json) return;
			nextInfo.viewportEl = viewportEl;
			nextInfo.containerEl = containerEl;
			setInfo(nextInfo as ViewportInfo);
		};

		const timer = setInterval(handleEvent, 2000);
		window.addEventListener('resize', handleEvent);
		window.addEventListener('orientationchange', handleEvent);
		return () => {
			clearInterval(timer);
			window.removeEventListener('resize', handleEvent);
			window.removeEventListener('orientationchange', handleEvent);
		};
	}, [viewportRef, containerRef, infoConstant, viewport]);

	useEffect(() => {
		console.debug('zoom calc');
		if (viewport.width === undefined) return;
		if (viewport.height === undefined) return;
		if (hasZoom) return;
		const rect = containerEl?.getBoundingClientRect();
		if (rect === undefined) return;
		const nextZoom1 = (rect.width / viewport.width) * 0.99;
		const nextZoom2 = (rect.height / viewport.height) * 0.99;
		setZoom(nextZoom1 < nextZoom2 ? nextZoom1 : nextZoom2);
	}, [viewport.width, viewport.height, windowSize.width, windowSize.height, containerEl, hasZoom])

	const tr = useTr('EditorViewport');

	console.debug('EditorViewport', { info });

	const handleSave = async () => {
		console.debug('handleSave');
		const rootData = nodes.exportData();
		console.debug('handleSave ->', rootData);
		function removeUndefined(node: any) {
			if (typeof node !== 'object') return;
			if (Array.isArray(node)) {
				node.forEach((child, i) => {
					if (child === undefined) node[i] = null;
					removeUndefined(child);
				});
			}
			for (const prop in node) {
				const child = node[prop];
				if (child === undefined) node[prop] = null;
				removeUndefined(child);
			}
		}
		removeUndefined(rootData);

		const deviceId = deviceId$.value;
		if (!deviceId) return;

		await dbUpdate(dbDeviceConfigPath(deviceId), { loadURL: getKioskUrl(deviceId) });
		await dbUpdate(dbDeviceConfigPlaylistPath(deviceId), {
			root: rootData,
			lastUpdated: serverTimestamp(),
		});

		pageMode$.next(PageMode.Open);
	};

	const handleLeave = () => {
		console.debug('handleLeave');

		const deviceId = deviceId$.value;
		if (!deviceId) return;

		dbGet<DbPlaylist>(dbDeviceConfigPlaylistPath(deviceId)).then((playlist) => {
			nodes.importData(clone(playlist?.rootData || playlist?.root || {}));
			pageMode$.next(PageMode.Open);
		});
	};

	return (
		<div
			className={classes.root}
			onClick={(e) => {
				const containerEl = containerRef.current;
				console.debug('EditorViewport: background click', e, e.target, containerEl);
				if (e.target === containerEl) {
					setSelectedId();
				}
			}}
		>
			<div ref={containerRef} className={classes.container}>
				{viewport && (
					<div
						ref={viewportRef}
						className={classes.view}
						style={{
							top: '50%',
							left: '50%',
							marginTop: -(viewport.height || 0) / 2,
							marginLeft: -(viewport.width || 0) / 2,
							width: viewport.width,
							height: viewport.height,
							transform: `scale(${zoom})`,
						}}
						onClick={(e) => {
							console.debug('EditorViewport: viewport click', e);
							const node = nodeElToNode(e.target);
							if (node) setSelectedId(node.id);
						}}
					>
						{<BlockFactory itemId={nodes.getRoot().id} />}
					</div>
				)}
			</div>

			<div className={classes.topbar}>
				<div className={classes.topbarDiv}>
					{tr('zoom', undefined, 'Zoom :')}
					<input
						value={Math.round(zoom * 100) / 100}
						onChange={(e) => {
							setZoom(nbr(e.target.value));
						}}
					/>
					<Fab
						className={classes.topbarFab}
						size="small"
						color="secondary"
						aria-label="screen_zoom_in"
						onClick={(e) => {
							console.debug('EditorViewport: zoom in click', e);
							setZoom((old) => old * 1.1);
						}}
					>
						<Icon>zoom_in</Icon>
					</Fab>
					<Fab
						className={classes.topbarFab}
						size="small"
						color="secondary"
						aria-label="screen_zoom_out"
						onClick={(e) => {
							console.debug('EditorViewport: zoom out click', e);
							setZoom((old) => old / 1.1);
						}}
					>
						<Icon>zoom_out</Icon>
					</Fab>
					<Fab
						className={classes.topbarFab}
						size="small"
						aria-label="screen_fullscreen"
						onClick={(e) => {
							console.debug('EditorViewport: fullscreen click', e);
							const deviceId = deviceId$.value;
							updateUrlParams({ deviceId, mode: PageMode.View });
						}}
					>
						<Icon>fullscreen</Icon>
					</Fab>
				</div>
				<div className={classes.topbarDiv}>
					<Fab
						className={classes.topbarFab}
						size="small"
						color="primary"
						aria-label="save"
						variant="extended"
						onClick={(e) => {
							console.debug('EditorViewport: save click', e);
							handleSave();
						}}
					>
						<SaveIcon />
						Enregistrer
					</Fab>
					<Fab
						className={classes.topbarFab}
						size="small"
						color="secondary"
						aria-label="close"
						variant="extended"
						onClick={(e) => {
							console.debug('EditorViewport: cancel click', e);
							handleLeave();
						}}
					>
						<BackIcon />
						Retour
					</Fab>
				</div>
			</div>
			{info && <EditorSelected viewportInfo={info} />}
		</div>
	);
}
