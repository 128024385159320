import React, { useEffect, useRef } from 'react';
import nodes from '~src/controllers/NodesController';
import clsx from '~src/helpers/clsx';
import useObservable from '~src/hooks/useObservable';
import typeInfoMap$ from '~src/messages/typeInfoMap$';
import { getIcon } from './Icon';
import { makeStyles } from '@mui/styles';
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import templates from './templates';
import { NType } from '~src/models/Node';
import ExpandedIcon from '@mui/icons-material/ExpandMore';
import CollapsedIcon from '@mui/icons-material/ChevronRight';
import selectedId$, { setSelectedId } from '~src/messages/selectedId$';
import useNode from '~src/hooks/useNode';

export interface EditorTreeNodeProps {
	id: string;
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'scroll',
		paddingLeft: 0,
		backgroundColor: '#e7e7e7',
		padding: 12,
		paddingRight: 0,
		flex: 2,
	},
	node: {
		paddingLeft: 12,
		transition: '0.2s ease',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	nodeSelected: {},
	nodeName: {
		marginLeft: -12,
		padding: '3px 6px',
		color: '#333',
		display: 'flex',
		alignItems: 'center',
		'& > .icon': {
			fontSize: 24,
			color: '#555555',
			marginRight: 3,
		},
	},
	nodeNameSelected: {
		boxShadow: '0px 2px 6px 2px rgba(0,0,0,0.3)',
		backgroundColor: 'white',
		color: 'black',
		'& > svg': {
			color: 'black',
		},
	},
	selectTemplate: {
		width: 200,
	},
	nodeExpander: {
		width: 20,
		'& svg': {
			width: '0.7em',
    		height: '0.7em',
		}
	},
	nodeAddPage: {
		marginLeft: 24,
		padding: '3px 6px',
		color: '#333',
		display: 'flex',
		alignItems: 'center',
		'& > .icon': {
			fontSize: 24,
			color: '#555555',
			marginRight: 3,
		},
	}
}));

selectedId$.subscribe(selectedId => {
	let id = selectedId;
	while (nodes.exists(id)) {
		const parent = nodes.getParent(id);
		id = parent.id;
		if (parent?.collapsed) nodes.update(parent.id, { collapsed: undefined });
	}
});

// Only one page display
// pageId$.subscribe(pageId => {
// 	nodes.getPages().forEach(page => nodes.update(page.id, { collapsed: page.id === pageId ? undefined : true }));
// });

function EditorTreeNode(props: EditorTreeNodeProps) {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);
	const { id } = props;
	const selectedId = useObservable(selectedId$);
	const typeInfoMap = useObservable(typeInfoMap$);
	const node = useNode(id);
	const type = node.type || NType.None;
	const typeInfo = typeInfoMap && typeInfoMap[type];
	const Icon = typeInfo?.icon || getIcon('crop_square');
	const title = node.name || node.text || typeInfo?.title || type;
	const cls = node.class;
	const isSelected = id === selectedId;
	const childIds = node.childIds || [];

	console.debug('EditorTreeNode', id, node);

	useEffect(() => {
		if (isSelected && ref.current) {
			ref.current.children[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		}
	}, [isSelected, ref]);

	return (
		<div
			ref={ref}
			id={id + '_tree'}
			className={clsx(classes.node, isSelected && classes.nodeSelected)}
			onClick={() => setSelectedId(id)}
		>
			<div className={clsx(classes.nodeName, isSelected && classes.nodeNameSelected)}>
				<div className={classes.nodeExpander} onClick={() => nodes.update(node.id, { collapsed: node.collapsed ? undefined : true })}>
					{childIds.length ? (node.collapsed ? <CollapsedIcon /> : <ExpandedIcon />) : null}
				</div>
				<Icon /> {title}
				{cls && ` (${cls})`}
			</div>
			{!node.collapsed && childIds.map((id) => (
				<EditorTreeNode key={id} id={id} />
			))}
		</div>
	);
}

export default function EditorTree() {
	const classes = useStyles();

	console.debug('EditorTree');

	const IconPage = getIcon('post_add');

	return (
		<div className={classes.root} onClick={(e) => {
			let el = e.target as HTMLElement | null;
			console.debug('EditorTree handleClick', el);
			while (el) {
				const id = (el.id || '').replace('_tree', '');
				if (nodes.exists(id)) return setSelectedId(id);
				el = el.parentElement;
			}
		}}>
			<EditorTreeNode id={nodes.getRoot().id} />
			<div className={classes.nodeAddPage}>
				<IconPage />
				<FormControl className={classes.selectTemplate} size="small">
					<InputLabel id="selectTemplate">Ajouter une page :</InputLabel>
					<Select labelId="selectTemplate" value={''} label="Ajouter une page :" onChange={(e) => {
						console.log('handleTemplateSelect', e, e.target.value);
						const key = e.target.value;
						const node = nodes.importData(templates[key].data, nodes.getRoot().id);
						setSelectedId(node.id);
					}}>
						<MenuItem value=""></MenuItem>
						{Object.entries(templates).map(([key, value]) => (
							<MenuItem key={key} value={key}>
								{value.title}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
}
