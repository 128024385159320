import NodeWithChildren from '~src/models/Node';

const win = window as any;

export interface IBridge {
    getPlaylist(): Promise<{ root: NodeWithChildren }>
    appRelaunch(): Promise<void>;
    download(url: string): Promise<string>;
}

export const bridge = win.bridge as IBridge
export default bridge