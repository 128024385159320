import { styled } from '@mui/material';

const PropRow = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	'& .MuiFormControl-root': {
		flex: 1,
	},
	'& .MuiButton-root': {
		flex: 1,
	},
}));

export default PropRow;