import { styled } from '@mui/material';

const PropColumn = styled('div')(({ theme }) => ({
	margin: theme.spacing(0.5),
	display: 'flex',
	flexDirection: 'column',
	'& .MuiFormControl-root': {
		margin: '8px 4px',
	},
	'& .MuiButton-root': {
		margin: theme.spacing(0.5),
	},
}));

export default PropColumn;