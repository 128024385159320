import { Button, LinearProgress, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import nodes from '~src/controllers/NodesController';
import useObservable from '~src/hooks/useObservable';
import deviceId$ from '~src/messages/deviceId$';
import { dbObserveItem, dbSet } from '~src/services/db';
import { storageTransferred$, storageUpload } from '~src/services/storage';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function VideoField({ label, itemId, prop }: FieldProp) {
	const progress = useObservable(storageTransferred$) || 0;
	const deviceId = deviceId$.value;
	const transcodingKey = `/transcodings/${deviceId}/${itemId}`;
	const transcodingObservable = useMemo(() => dbObserveItem(transcodingKey), [transcodingKey]);
	const transcodingData = useObservable(transcodingObservable);
	console.debug('VideoField transcodingData', transcodingData);
	const transcodingProgress = Number(transcodingData?.progress);

	useEffect(() => {
		if (!transcodingData) return;
		const { progress, stat, webmUrl, mp4Url, coverUrl } = transcodingData as any;
		if (progress === 1 && stat === 'success') {
			nodes.setPropString(itemId, 'coverUrl', coverUrl);
			nodes.setPropString(itemId, 'webmUrl', webmUrl);
			nodes.setPropString(itemId, 'mp4Url', mp4Url);
		}
	}, [itemId, transcodingData]);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (!file) return;
        nodes.setPropString(itemId, prop, undefined);
        storageUpload(file).then((url) => {
			dbSet(transcodingKey, {
				deviceId,
				itemId,
				sourceUrl: url,
				progress: 0,
				stat: null,
			});
			nodes.setPropString(itemId, prop, url);
		});
	};

	const handleTranscoding = () => {
		dbSet(transcodingKey, {
			deviceId,
			itemId,
			sourceUrl: nodes.getPropString(itemId, prop, ''),
			progress: 0,
			stat: null,
		});
	};

	return (
		<>
			<PropDelete itemId={itemId} prop={prop}>
				<TextField
					id={`${itemId}-${String(prop)}`}
					label={label}
					value={nodes.getPropString(itemId, prop, '')}
					onChange={(e) => nodes.setPropString(itemId, prop, e.target.value)}
				/>
				<input
					style={{ display: 'none' }}
					id={`${itemId}-${String(prop)}-file`}
					name="upload"
					type="file"
					onChange={handleFileChange}
				/>
				<label htmlFor={`${itemId}-${String(prop)}-file`}>
					<Button size="small" color="secondary" variant="contained" component="span">
						Charger
					</Button>
				</label>
			</PropDelete>
			{progress > 0 && progress < 1 ? <LinearProgress variant="determinate" value={progress * 100} /> : null}
			{(!Number.isNaN(transcodingProgress) && transcodingProgress < 1) && (
				<>
					<span>Réencodage de la vidéo :</span>
					<LinearProgress variant="determinate" value={transcodingProgress * 100}  />
				</>
			)}
			<Button size="small" color="secondary" variant="contained" component="span" onClick={handleTranscoding}>
				Forcer le Réencodage
			</Button>
		</>
	);
}
