import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { fr } from 'date-fns/locale';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function IntervalField({ label, itemId, prop }: FieldProp) {
	const value = nodes.getPropNumber(itemId, prop, null);
	const date = value !== null ? new Date(value) : null;
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
			<PropDelete itemId={itemId} prop={prop}>
				<TimePicker
					label={label}
					value={date}
					onChange={(date: any) => {
						console.debug('IntervalField onChange', prop, date);
						nodes.setPropNumber(itemId, prop, date ? date.getTime() : null);
					}}
					renderInput={(params: any) => <TextField id={`${itemId}-${String(prop)}`} {...params} />}
				/>
			</PropDelete>
		</LocalizationProvider>
	);
}
