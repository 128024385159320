import { TextField } from '@mui/material';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import { nbr } from '~src/helpers/converters';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function IntervalField({ label, itemId, prop }: FieldProp) {
	return (
		<PropDelete itemId={itemId} prop={prop}>
			<TextField
				id={`${itemId}-${String(prop)}`}
				label={label}
				value={nodes.getPropNumber(itemId, prop, 0) / 1000}
				onChange={(e) => nodes.setPropNumber(itemId, prop, nbr(e.target.value, 0) * 1000)}
				type="number"
				size="small"
			/>
		</PropDelete>
	);
}
