import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import EditorViewport from './EditorViewport';
import EditorDrawer from './EditorDrawer';
import nodes from '~src/controllers/NodesController';
import clone from '~src/helpers/clone';
import EditorTree from './EditorTree';
import EditorProperties from './EditorProperties';
import selectedId$ from '~src/messages/selectedId$';
import useObservable from '~src/hooks/useObservable';
import screenInfo$ from '~src/messages/screenInfo$';
import viewport$ from '~src/messages/viewport$';
import { dbDeviceConfigPlaylistPath } from '~src/services/db';
import useDbItem from '~src/hooks/useDbItem';
import DbPlaylist from '~src/models/DbPlaylist';
import deviceId$ from '~src/messages/deviceId$';
import { NType } from '~src/models/Node';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		width: '100%',
		height: '100%',
	},
}));

export default function Editor() {
	const classes = useStyles();
	const screenInfo = useObservable(screenInfo$);
	const deviceId = useObservable(deviceId$);
	const selectedId = useObservable(selectedId$);
	const viewport = useObservable(viewport$);
	const playlist = useDbItem<DbPlaylist>(dbDeviceConfigPlaylistPath(deviceId));

	console.debug('Editor', { screenInfo, deviceId, selectedId, viewport, playlist });

	useEffect(() => {
		const s = nodes.updated$.subscribe(() => {
			const root = nodes.getRoot();
			if (root.type !== NType.Root) {
				nodes.update(root.id, { type: NType.Root });
			}
			for (const child of nodes.getChildren(root.id)) {
				if (child.type !== NType.Page) {
					nodes.update(child.id, { type: NType.Page });
				}
			}
		});
		return () => s.unsubscribe();
	}, []);

	useEffect(() => {
		nodes.importData(clone(playlist?.rootData || playlist?.root || {}));
		setTimeout(() => nodes.updated(), 1000);
	}, [playlist, playlist?.rootData, playlist?.root]);

	useEffect(() => {
		viewport$.next({
			top: 0,
			left: 0,
			scale: 0.5,
			cols: 100,
			rows: 100,
			width: screenInfo.width,
			height: screenInfo.height,
		});
	}, [screenInfo]);

	return viewport && nodes ? (
		<div className={classes.root} key={deviceId}>
			<EditorDrawer anchor="left" width={300}>
				<EditorTree />
			</EditorDrawer>
			<EditorViewport />
			{selectedId && (
				<EditorDrawer anchor="right" width={400}>
					<EditorProperties />
				</EditorDrawer>
			)}
		</div>
	) : (
		<div className={classes.root}>Chargement...</div>
	);
}
