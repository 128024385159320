import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const fireConfig = {
	apiKey: 'AIzaSyCTimjR8GKIIxYHmG4p1m8mmA8-3ggarIU',
	authDomain: 'cafe-1fdf3.firebaseapp.com',
	databaseURL: 'https://cafe-1fdf3.firebaseio.com',
	projectId: 'cafe-1fdf3',
	storageBucket: 'cafe-1fdf3.appspot.com',
	messagingSenderId: '504669440353',
	appId: '1:504669440353:web:be455a9b3a7c4dfef81d20',
	measurementId: 'G-ZHVBJFLS7T',
};

export const firebaseApp = initializeApp(fireConfig);
export const analytics = getAnalytics(firebaseApp);

export default firebaseApp;
