import {O} from 'ts-toolbelt';

export interface NPropDef {
    render?: string;
    label?: string;
    values?: { [value: string]: string };
    canCustom?: boolean;
    isAdvanced?: boolean;
    isOptional?: boolean;
}

export type NImage = {
	source?: string;
	name?: string;
	size?: [number, number];
	mode?: string;
	file?: string;
	type?: string;
	url?: string;
	waitResize?: Date;
};

export type NVideo = {
	source?: string;
	name?: string;
	size?: [number, number];
	mode?: string;
	file?: string;
	type?: string;
	url?: string;
	waitResize?: Date;
};

export type NPDF = {
	source?: string;
	name?: string;
	size?: [number, number];
	mode?: string;
	file?: string;
	type?: string;
	url?: string;
	waitResize?: Date;
};

export type NFile = string;
export type NText = string;
export type NColor = string;
export type NPos = [string|number, string|number, string|number, string|number]; // top, right, bottom, left
export type NSize = [string|number, string|number]; // width, height

export type NTranslate = Record<string, Partial<Node>>;

// export interface NData extends Omit<Omit<Omit<Node, 'childIds'>, 'parentId'>, 'id'> {
// 	readonly id?: string;
// 	readonly children?: ReadonlyArray<NData>;
// }


export type NProp = keyof Node;

export interface NAction {
	readonly sendTo?: string;
	readonly url?: string;
	readonly expr?: string;
};

export type NCss = string;
export type NScriptObject = {
	readonly js: string;
	readonly jsx?: string;
	readonly to?: string;
	readonly update?: Partial<Node>;
	
	readonly sendTo?: string;
	readonly url?: string;
	readonly expr?: string;
};
export type NScript = string | NScriptObject;

export enum NType {
	None = '',
	Block = 'block',
	Web = 'web',
	Pdf = 'pdf',
	Video = 'video',
	Youtube = 'youtube',
	Image = 'image',
	Text = 'text',
	Button = 'button',
	Carousel = 'carousel',
	Root = 'root',
	Page = 'page',
}

export interface NData {
	readonly id?: string;
	readonly type?: NType;
	readonly name?: string;
	readonly class?: string;
	readonly style?: React.CSSProperties;
	readonly attrs?: React.HTMLAttributes<HTMLDivElement>;
	readonly layout?: 'absolute' | 'column' | 'row';
	readonly x?: number;
	readonly y?: number;
	readonly w?: number;
	readonly h?: number;
	readonly bgColor?: string;
	readonly margin?: number;
	readonly flex?: number;
	readonly flexInline?: string;
	readonly flexReverse?: string;
	readonly flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
	readonly justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
	readonly alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
	readonly alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch';
	readonly onClick?: NAction;
	readonly url?: string;
	readonly webmUrl?: string;
	readonly mp4Url?: string;
	readonly coverUrl?: string;
	readonly gs?: string;
	readonly interval?: number;
	readonly beginAt?: number;
	readonly endAt?: number;
	readonly svg?: string;
	readonly iconSize?: number;
	readonly text?: string;
	readonly html?: string;
	readonly color?: string;
	readonly fontSize?: number;
	readonly dateStart?: number; // Math.round(new Date().getTime()/1000)
	readonly dateEnd?: number; // Math.round(new Date().getTime()/1000)
	readonly timeStart?: number; // 1day=24*60*60 ; 1hour=60*60 ; 1min=60
	readonly timeEnd?: number; // 1day=24*60*60 ; 1hour=60*60 ; 1min=60
	readonly displayDays?: string; // 1:Lundi | 2:Mardi | 3:Mercredi | 4:Jeudi | 5:Vendredi | 6:Samedi
	readonly collapsed?: boolean;
	readonly hidden?: boolean;
	readonly css?: string;
}
export type WNData = O.Writable<NData>;

export default interface Node extends NData {
	readonly id: string;
	readonly parentId: string;
	readonly childIds: ReadonlyArray<string>;
}
export type WNode = O.Writable<Node>;

export type NMap = Readonly<Record<string, Node>>;
export type WNMap = Record<string, WNode>;

export interface NExport extends NData {
	readonly children?: ReadonlyArray<NExport>;
}
export interface WNExport extends WNData {
	children?: WNExport[];
}

	// readonly t?: string;
	// readonly s?: NodeSize; // size
	// readonly p?: NodePos; // position
	// readonly ma?: NodePos; // margin
	// readonly pa?: NodePos; // padding
	// readonly name?: string;
	// readonly cls?: string;
	// readonly style?: { [prop: string]: string };
	// readonly attrs?: { [prop: string]: string | number };
	// readonly fontFamily?: string;
	// readonly flex?: number | string;
	// readonly flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
	// readonly justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
	// readonly alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
	// readonly alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch';
	// readonly bgImg?: NodeImage;
	// readonly bgColor?: NodeColor;
	// readonly bgPosition?: string;
	// readonly bgRepeat?: string;
	// readonly bgSize?: string;
	// readonly color?: NodeColor;
	// readonly fontSize?: string;
	// readonly hidden?: boolean;
	// readonly css?: NodeCss;

	// readonly script?: NodeScript;
	// readonly onClick?: ItemAction;
	
	// // Html & Template
	// readonly html?: string;
	// readonly htmlTag?: string;

	// // Carousel
	// readonly clsIn?: string;
	// readonly clsOut?: string;

	// // Button
	// // readonly onClick?: NAction;
	// // readonly iconSize?: number;

	// // Root & Template
	// readonly title?: string;

	// // Img & Translate
	// readonly img?: NodeImage;

	// // Video
	// readonly video?: NodeVideo;

	// // PDF
	// readonly pdf?: NodePDF;

	// // PDF & Carousel
	// readonly interval?: number;

	// // Root
	// readonly googleFonts?: string;
	// readonly titleFontFamily?: string;
	// readonly templateHTML?: string;

	// // Root & Translate
	// readonly language?: string;

	// // Translate
	// readonly tr?: NodeTranslate;
	// readonly trH?: string;

	// // Template
	// readonly key?: string;
	// readonly propDefs?: Record<string, PropDef>;
	// readonly render?: NodeScript;
	// readonly icon?: string;