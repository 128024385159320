import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, MenuItem, Switch, TextareaAutosize, TextField } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import useObservable from '~src/hooks/useObservable';
import DbPlaylist from '~src/models/DbPlaylist';
import { dbConsoleIsRemotePath, dbConsoleLogsPath, dbConsoleRunPath, dbConsolePath, dbDeviceNamePath, dbMonitorEndPath, dbMonitorStartPath, dbDeviceConfigPlaylistPath, dbSet } from '~src/services/db';
import deviceId$ from '~src/messages/deviceId$';
import useDbVal from '~src/hooks/useDbVal';

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		margin: 0,
		width: '100%',
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		overflowY: 'auto',
	},
	console: {
		height: 400,
		margin: 0,
		padding: theme.spacing(1),
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		background: 'black',
		color: '#85ff85',
		overflowY: 'auto',
		'& span': {
			color: '#FFFFFF',
		},
	},
	consoleItems: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		'& input': {
			flex: 1,
			margin: theme.spacing(0.5),
			fontSize: '110%',
		},
		'& button': {
			margin: theme.spacing(0.5),
		},
		'& textarea': {
			flex: 1,
		},
		'& .MuiButton-root': {
			margin: theme.spacing(0.5),
		},
    },
    propField: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
    }
}));

export interface DeviceRun {
	title?: string;
	expr?: string;
	type?: string;
	timeout?: number;
}

export interface DeviceOptions {
	isRemote?: boolean;
}

export interface DeviceConsoleData {
	logError?: string;
	logIndex?: number;
	logs?: string[];
	options?: DeviceOptions;
	run?: DeviceRun;
	runLast?: DeviceRun;
	runResult?: any;
}

function DeviceLog({ log }: { log: string }) {
	const logType = log && log.substr(log.indexOf(' ') + 1, 5);
	switch (logType) {
		case 'KIOSK':
			return <span style={{ color: '#6c6cff' }}>{log}</span>;
		case 'ERROR':
			return <span style={{ color: '#FF0000' }}>{log}</span>;
		case 'DEBUG':
			return <span style={{ color: '#AAAAAA' }}>{log}</span>;
		case 'WARN ':
			return <span style={{ color: '#FFFF00' }}>{log}</span>;
		default:
			return <span>{log}</span>;
	}
}

const commands: DeviceRun[] = [
	{
		title: 'kill edge',
		expr: `(async () => { await api.ps('taskkill /F /IM msedge.exe'); await api.ps('taskkill /F /IM MicrosoftEdge.exe'); })()`,
	},
	{ title: 'Capture', expr: `api.screenshot();` },
	{ title: 'Fermer le kiosk', expr: `api.kioskClose()` },
	{ title: 'Ouvrir le kiosk', expr: `api.kioskOpen()` },
	{ title: 'Redémarrer le kiosk', expr: `api.kioskReload()` },
	{ title: 'Afficher le kiosk', expr: `api.sendAltTab()` },
	{ title: 'Rafraichir le kiosk', expr: `api.nircmd('sendkeypress F5')` },
	{
		title: 'Mettre à jour',
		expr: `api.ps("iex ((New-Object System.Net.WebClient).DownloadString('https://kiosk-service.web.app/update.ps1'))")`,
		timeout: 10 * 60 * 1000,
	},
	{
		title: 'MAJ script',
		expr: `api.ps("iex ((New-Object System.Net.WebClient).DownloadString('https://kiosk-service.web.app/update-script.ps1'))")`,
		timeout: 10 * 60 * 1000,
	},
	{
		title: 'MAJ kiosk',
		expr: `api.ps("iex ((New-Object System.Net.WebClient).DownloadString('https://kiosk-service.web.app/update-kiosk.ps1'))")`,
		timeout: 10 * 60 * 1000,
	},
	{ title: 'Redémarrer le service', expr: `api.ps('pm2 restart service')` },
	{ title: "Redémarrer l'ordinateur", expr: `api.nircmd('exitwin reboot')` },
	{ title: 'Windows Version', expr: `api.cmd('ver')` },
	{ title: 'Get HostName', expr: `api.ps('hostname')` },
	{ title: 'Redémarrer dans 5 minutes', expr: `
clearTimeout(api._rebootTimeout);
api._rebootTimeout = setTimeout(() => {
	api.nircmd('exitwin reboot');
}, 5 * 60 * 1000);` },
	{ title: 'Send to ks.js', expr: `
	clearTimeout(api._rebootTimeout);
	api._rebootTimeout = setTimeout(() => {
	api.nircmd('exitwin reboot');
	}, 5 * 60 * 1000);` },
];

function PropField({ label, path, width }: { label: string; path: string; width: number }) {
	const classes = useStyles();
	const [value, setValue] = useState('...');
	const deviceValue = useDbVal(path, '');
	if (deviceValue && value === '...') setValue(deviceValue);

	return (
		<div className={classes.propField}>
			<TextField label={label} value={value} style={{ width, margin: 2 }} onChange={(e) => setValue(e.target.value)} />
			<Button
				style={{ margin: 2 }}
				size="small"
				variant="contained"
				color="primary"
				onClick={() => dbSet(path, value)}
			>
				SET
			</Button>
		</div>
	);
}

export default function DeviceConsole() {
	const classes = useStyles();
	const logsRef = useRef<HTMLDivElement>(null);
	const [run, setRun] = useState<DeviceRun>({});
	const deviceId = useObservable(deviceId$);
	const deviceConsole = useDbVal<DeviceConsoleData>(dbConsolePath(deviceId));
	const devicePlaylist = useDbVal<DbPlaylist>(dbDeviceConfigPlaylistPath(deviceId));

	if (deviceConsole?.runLast?.expr && !run.expr) setRun(deviceConsole.runLast);

	const setIsRemote = (isRemote: boolean) => {
		console.debug('DeviceConsole setIsRemote', isRemote);
		dbSet(dbConsoleIsRemotePath(deviceId), isRemote);
		if (!isRemote) {
			dbSet(dbConsoleLogsPath(deviceId), null);
		}
	};

	const runUpdate = (runUpdate: Partial<DeviceRun>) => {
		const nextRun = { expr: '', type: 'js', timeout: 60000, ...runUpdate };
		console.debug('DeviceConsole runUpdate nextRun', runUpdate, nextRun);
		setRun(nextRun);
		dbSet(dbConsoleRunPath(deviceId), nextRun);
	};

	let logs: string[] = [];
	if (deviceConsole && deviceConsole.logs) {
		const a = [...deviceConsole.logs];
		const b = a.splice((deviceConsole.logIndex || 0) + 1);
		logs = [...b, ...a];
	}

	const win = window as any;

	return (
		<div className={classes.root}>
			<div className={classes.console} ref={logsRef}>
				{logs.map((log, i) => (
					<DeviceLog key={i} log={log} />
				))}
			</div>
			{deviceConsole?.logError && (
				<div className={classes.consoleItems}>
					LogError: <TextareaAutosize value={deviceConsole.logError} />
				</div>
			)}
			<div className={classes.consoleItems}>
				<TextareaAutosize value={run.expr} onChange={(e) => setRun({ ...run, expr: e.target.value })} />
				{deviceConsole?.runResult && (
					<>
						<ArrowRightIcon />
						<TextareaAutosize value={deviceConsole.runResult} />
					</>
				)}
			</div>
			<div className={classes.consoleItems}>
				<TextField label="Id" value={deviceId} style={{ width: 300 }} disabled />
				<PropField label="Nom" path={dbDeviceNamePath(deviceId)} width={250} />
				<PropField label="Start" path={dbMonitorStartPath(deviceId)} width={80} />
				<PropField label="End" path={dbMonitorEndPath(deviceId)} width={80} />
			</div>
			<div className={classes.consoleItems}>
				<TextField
					select
					label="Type"
					value={run.type || 'js'}
					style={{ width: 10, margin: 2 }}
					onChange={(e) => setRun({ ...run, type: e.target.value })}
				>
					<MenuItem value={'js'}>js</MenuItem>
					<MenuItem value={'ps'}>ps</MenuItem>
					<MenuItem value={'cmd'}>cmd</MenuItem>
					<MenuItem value={'nircmd'}>nircmd</MenuItem>
				</TextField>
				<TextField
					type="number"
					label="Timeout"
					value={run.timeout || 60000}
					style={{ width: 70 }}
					onChange={(e) => {
						const timeout = Number(e.target.value);
						if (!Number.isNaN(timeout)) setRun({ ...run, timeout });
					}}
				/>
				<Button size="small" variant="contained" color="primary" onClick={() => runUpdate(run)}>
					ENVOYER
				</Button>
				<Button
					size="small"
					variant="contained"
					color="primary"
					onClick={() => {
						runUpdate({
							expr: `(async () => { await api.nircmd('sendkeypress ${run.expr}'); await api.screenshot(); await api.sleep(5000); await api.screenshot(); })()`,
						});
					}}
				>
					ENVOYER TOUCHE
				</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => (win._playlist = devicePlaylist)}>
					COPIER PLAYLIST
				</Button>
				<Button
					size="small"
					variant="contained"
					color="primary"
					onClick={() => dbSet(dbDeviceConfigPlaylistPath(deviceId), win._playlist)}
				>
					COLLER PLAYLIST
				</Button>
				<Button
					size="small"
					variant="contained"
					color="secondary"
					onClick={() => logsRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })}
				>
					DÉFILER JUSQU'À LA FIN
				</Button>
				<Switch
					checked={deviceConsole?.options?.isRemote || false}
					onChange={(e, isRemote) => setIsRemote(isRemote)}
					name="isRemote"
				/>
			</div>
			<div className={classes.consoleItems}>
				{commands.map((command, i) => (
					<Button key={i} size="small" variant="contained" color="primary" onClick={() => runUpdate(command)}>
						{command.title}
					</Button>
				))}
			</div>
		</div>
	);
}
