import global from '../global';
import { Fully } from './fullySettings';
import { BridgeNative, DownloadFile, DownloadFileResult, ScreenSize, SoftInfo } from "./interfaces";

console.debug('fullyBridge');

const fully: Fully = global.fully;

let bridge: BridgeNative | undefined = undefined;

if (fully) {
    const keyMethodMap: Record<string, () => void> = {
        'ctrl+shift+r': () => {
            fully.clearWebstorage();
            fully.clearFormData();
            fully.clearCookies();
            fully.clearHistory();
            fully.clearCache();
            fully.restartApp();
        },
        'ctrl+r': () => fully.loadStartUrl(),
        'ctrl+h': () => fully.bringToBackground(),
        'ctrl+e': () => fully.exit(),
    };
    document.addEventListener('keydown', (e) => {
        let key = e.key.toLowerCase();
        if (e.altKey) key = `alt+${key}`;
        if (e.shiftKey) key = `shift+${key}`;
        if (e.ctrlKey) key = `ctrl+${key}`;
        fully.setMessageOverlay(key);
        if (keyMethodMap[key]) keyMethodMap[key]();
    });

    const ip = fully.getIp4Address();
    const networkState = fully.isNetworkConnected() ? 'Connected' : 'Disconnected';
    fully.setMessageOverlay(`${ip} ${networkState} v${global.version}`);

    fully.bind('onDownloadSuccess', 'onFullyDownloadSuccess("$url","$dir","$code","$fileLength","$lastModified","$mimetype");');
    fully.bind('onDownloadFailure', 'onFullyDownloadFailure("$url","$dir","$code");');

    const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

    const DOWNLOAD_DIR = '/sdcard/Download/kiosk';
    const DOWNLOAD_URL = 'https://localhost/sdcard/Download/kiosk';

    fully.createFolder(DOWNLOAD_DIR);

    global._fullyDownloadPromises = {};

    global.onDownloadSuccess = (url: string, dir: string, code: string, fileLength: string, lastModified: string, mimetype: string) => {
        const key = dir + url;
        if (global._fullyDownloadPromises[key]) {
            global._fullyDownloadPromises[key].resolve({ url, dir, code, fileLength, lastModified, mimetype });
            delete global._fullyDownloadPromises[key];
        }
    };

    global.onDownloadFailure = (url: string, dir: string, code: string) => {
        const key = dir + url;
        if (global._fullyDownloadPromises[key]) {
            global._fullyDownloadPromises[key].reject(new Error('downloadFailure:' + code));
            delete global._fullyDownloadPromises[key];
        }
    };

    const downloadFile = async (url: string): Promise<DownloadFileResult> => {
        const key = DOWNLOAD_DIR + url;
        const pathName = new URL(url).pathname;
        const fileName = pathName.substring(pathName.lastIndexOf('/') + 1);
        return new Promise((resolve, reject) => {
            global._fullyDownloadPromises[key] = { resolve, reject };
            fully.downloadFile(url, DOWNLOAD_DIR);
        }).then((res: any) => {
            return {
                fileName,
                downloadUrl: url,
                code: Number(res.code),
                fileLength: Number(res.fileLength),
                lastModified: Number(res.lastModified),
                mimetype: res.mimetype,
                localUrl: `${DOWNLOAD_URL}/${fileName}`,
            };
        });
    }

    const clearDownloadFiles = async () => {
        await sleep(5);
        fully.emptyFolder(DOWNLOAD_DIR);
        await sleep(5);
    }

    const getDownloadFiles = async (): Promise<DownloadFile[]> => {
        await sleep(5);
        const files = JSON.parse(fully.getFileList(DOWNLOAD_DIR)) as {
            type: string,
            name: string,
            size: number,
            lastModified: number,
            canRead: boolean,
            canWrite: boolean,
            isHidden: boolean,
        }[];
        await sleep(5);
        return files.map(f => ({
            fileName: f.name,
            isFile: f.type === 'file',
            size: f.size,
            lastModified: f.lastModified,
            canRead: f.canRead,
            canWrite: f.canWrite,
            isHidden: f.isHidden,
            localUrl: `${DOWNLOAD_URL}/${f.name}`,
        } as DownloadFile));
    }

    const saveJsonStorage = async (json: string): Promise<void> => {
        await fully.setStringSetting('my_storage', json);
    }

    const loadJsonStorage = async (): Promise<string> => {
        return await fully.getStringSetting('my_storage');
    }

    const getSoftInfo = async (): Promise<SoftInfo> => {
        return await {
            os: `android:${fully.getAndroidVersion()}.${fully.getAndroidSdk()}`,
            soft: `fully:${fully.getFullyVersion()}`,
            webview: `webview:${fully.getWebviewVersion()}`,
        };
    }

    const getScreenSize = async (): Promise<ScreenSize> => {
        return await {
            width: fully.getDisplayWidth(),
            height: fully.getDisplayHeight(),
        };
    }

    const getScreenshotPngBase64 = async (): Promise<string> => {
        return await fully.getScreenshotPngBase64();
    }

    const takeScreenshot = async (): Promise<string> => {
        return await fully.getScreenshotPngBase64();
    }

    const setScreenOn = async (value: boolean): Promise<void> => {
        if (value) await fully.turnScreenOn();
        else await fully.turnScreenOff(true);
    }

    const setKiosk = async (value: boolean): Promise<void> => {
        if (value) {
            await fully.bringToForeground();
        }
        else {
            await fully.bringToBackground();
        }
    }

    const getKiosk = async (): Promise<boolean> => {
        return await fully.isInForeground();
    }

    const reload = async () => {
        await fully.clearCache();
        await sleep(100);
        await fully.loadStartUrl();
    }

    const reboot = async () => {
        await fully.reboot();
    }

    const restart = async () => {
        await fully.clearCache();
        await sleep(100);
        await fully.restartApp();
    }

    const exit = async () => {
        await fully.exit();
    }

    const reset = async () => {
    }

    const bridgeInvoke = async (method: string, ...args: any[]) => {
        return await (fully as any)[method](...args);
    }

    const getBridgeMethods = async () => {
        return await Object.keys(fully);
    }

    setInterval(() => {
        const networkConnected = fully.isNetworkConnected();
        fully.setMessageOverlay(networkConnected ? '' : '.');
    }, 5000);

    bridge = {
        downloadFile,
        clearDownloadFiles,
        getDownloadFiles,
        saveJsonStorage,
        loadJsonStorage,
        getSoftInfo,
        getScreenSize,
        getScreenshotPngBase64,
        setScreenOn,
        setKiosk,
        getKiosk,
        reload,
        reboot,
        restart,
        exit,
        reset,
        bridgeInvoke,
        getBridgeMethods,
    };
}

export default bridge;