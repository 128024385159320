import * as React from 'react';
import { FunctionComponent } from 'react';
import BlockProps from './BlockProps';
import useNode from '~src/hooks/useNode';
import { NType } from '~src/models/Node';

const _blocks: { [type: string]: FunctionComponent<BlockProps> } = {};

export function getRegisterTypes(): NType[] {
	return Object.keys(_blocks) as NType[];
}

export function blockRegister(x: NType, render: FunctionComponent<BlockProps>) {
	_blocks[x] = render as any;
}

export default function BlockFactory(props: BlockProps) {
	console.debug('Factory', props);
	const {type} = useNode(props.itemId);
	const Comp = (type && _blocks[type]) || _blocks.block;
	if (!Comp) throw new Error('Factory not block register ' + type);
	return React.createElement(Comp, props);
}
