const copyTextarea = document.createElement('textarea');
copyTextarea.id = 'copy-textarea';
Object.assign(copyTextarea.style, {
    border: '0',
    padding: '0',
    margin: '0',
    position: 'absolute',
    left: '-9999px',
    width: '100px',
    height: '100px',
    opacity: '0',
});
copyTextarea.setAttribute('readonly', '');
document.body.appendChild(copyTextarea);

export function paste(): string|null {
    copyTextarea.select();
    try {
        (document as any).execCommand("paste");
        copyTextarea.blur();
        return copyTextarea.value;
    } catch(error) {
        console.warn('copy error', error);
    }
    copyTextarea.blur();
    return null;
}

export function copy(value: string) {
    copyTextarea.value = value;
    copyTextarea.select();
    try {
        (document as any).execCommand("copy");
    } catch(error) {
        console.warn('copy error', error);
    }
    copyTextarea.blur();
}

export function copyJson(value: any) {
    try {
        copy(JSON.stringify(value, null, 2));
    } catch(error) {
        console.warn('copy error', error);
    }
}

export function pasteJson() {
    try {
        return JSON.parse(paste()||'null');
    } catch(e) {
        return null;
    }
}

const clipboard = { copy, paste, copyJson, pasteJson };

export default clipboard;