const tinycolor = require("tinycolor2");
(window as any)._tinycolor = tinycolor;

export function colorBrightness(val: string): number {
	return tinycolor(val).getBrightness();
}

export function colorRandom(): string {
	const h = Math.floor(Math.random() * 256);
	const s = Math.floor(Math.random() * 80) + 20;
	const l = Math.floor(Math.random() * 60) + 20;
	return tinycolor({ h, s, l }).toHexString();
}