import * as React from 'react';
import { useEffect } from 'react';
import BlockProps from './BlockProps';
import BlockFactory from './BlockFactory';
import useNode from '~src/hooks/useNode';
import Block from './Block';
import addCss from '~src/helpers/addCss';
import pageId$ from '~src/messages/pageId$';
import useObservable from '~src/hooks/useObservable';
import pageMode$, { PageMode } from '~src/messages/pageMode$';
import nodes from '~src/controllers/NodesController';

addCss('BlockRoot', `.b--root {}`);

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

type DateN = Date | number | string | undefined | null;

function getDate(value?: DateN) {
	return value ? (value instanceof Date ? value : new Date(value)) : new Date();
}

function getTime(value?: DateN) {
	return getDate(value).getTime();
}

function dayTime(value?: DateN) {
	return getTime(value) % DAY;
}

function addDate(value: DateN, time: number) {
	return getDate(getTime(value) + time);
}

function createUpdatePageTimer() {
	console.debug('autoUpdatePage');
	let lastPageUpdated = getDate();
	return setInterval(() => {
		const currId = pageId$.value;
		const nextId = (() => {
			const now = getDate();
			const nowTime = dayTime(now);
			const day = String(now.getDay());
			const pages = nodes.getChildren(nodes.getRoot().id).filter((page) => {
				if (page.displayDays && String(page.displayDays).indexOf(day) === -1) return false;
				if (page.dateStart && getDate(page.dateStart) > now) return false;
				if (page.dateEnd && addDate(page.dateEnd, DAY) < now) return false;
				if (page.timeStart && dayTime(page.timeStart) > nowTime) return false;
				if (page.timeEnd && dayTime(page.timeEnd) < nowTime) return false;
				return true;
			});
			const index = pages.findIndex((page) => page.id === currId);
			if (index === -1) return pages[0]?.id || '';
			const page = pages[index];
			if (page.interval && addDate(lastPageUpdated, page.interval) < now) {
				lastPageUpdated = now;
				return pages[(index + 1) % pages.length].id;
			}
			return currId;
		})();
		if (nextId !== currId) pageId$.next(nextId);
	}, 1000);
}

export default function BlockRoot(props: BlockProps) {
	const rootId = props.itemId;
	const root = useNode(rootId);
	// const pageIds = root.childIds;
	const pageMode = useObservable(pageMode$);
	const pageId = useObservable(pageId$);

	console.debug('BlockRoot', { props, root, pageMode, pageId });

	addCss('root-custom-css', root.css || '');

	useEffect(() => {
		if (pageMode === PageMode.Edit) return;
		const timer = createUpdatePageTimer();
		return () => clearInterval(timer);
	}, [pageMode]);

	return <Block {...props}>{pageId && <BlockFactory key={pageId} itemId={pageId} />}</Block>;
}
