import { NExport, NType } from "~src/models/Node";

export const templates: {
	[name: string]: { title: string; data: NExport };
} = {
	page: {
		title: 'Nouvelle Page',
		data: {
			name: 'page',
			type: NType.Page,
			children: [
				{
					bgColor: '#012560',
					h: 61,
					name: 'élément bleu',
					type: NType.Block,
					w: 60,
					x: 37,
					y: 9,
				},
			],
		},
	},
	page_pdf: {
		title: 'Nouvelle Page PDF',
		data: {
			name: 'Page',
			type: NType.Page,
			children: [{ type: NType.Pdf, name: 'pdf', bgColor: '#ffffff' }],
		},
	},
	page_web: {
		title: 'Nouvelle Page Web',
		data: {
			bgColor: '#012560',
			name: 'Page',
			type: NType.Page,
			children: [
				{ h: 90, name: 'Web', type: NType.Web, url: 'http://metro.fr', w: 100, x: 0, y: 0 },
				{
					bgColor: '#ededed',
					h: 10,
					layout: 'row',
					name: 'Navigation',
					type: NType.Block,
					w: 100,
					x: 0,
					y: 90,
					children: [
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'back' },
							text: '<',
							type: NType.Button,
							w: 8,
							x: 2,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'https://www.metro.fr/' },
							text: 'Accueil',
							type: NType.Button,
							w: 20,
							x: 12,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'https://www.metro.fr/services/creer-site-internet' },
							text: 'Créez un site web',
							type: NType.Button,
							w: 20,
							x: 32,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'https://www.metro.fr/services/menu-digital-restaurants' },
							text: 'Créez un menu digital',
							type: NType.Button,
							w: 20,
							x: 56,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: {
								sendTo: 'PageWeb',
								url: 'https://www.metro.fr/services/carte-metro-reflexe/delai-de-paiement-differe',
							},
							text: 'Reflexe Plus',
							type: NType.Button,
							w: 20,
							x: 78,
							y: 12.5,
						},
					],
				},
			],
		},
	},
	page_video: {
		title: 'Nouvelle Page Vidéo',
		data: {
			name: 'page vidéo',
			type: NType.Page,
			children: [
				{
					type: NType.Video,
					name: 'vidéo',
					bgColor: '#000000',
					url: 'https://firebasestorage.googleapis.com/v0/b/cafe-1fdf3.appspot.com/o/medias%2FlNTAeM1YqwftBP19xoPBAZiZCpF3%2F20210121132428_videotest1.mp4?alt=media&token=0bd928b8-d2df-4d98-8143-510c0fd57a9d',
					gs: 'gs://cafe-1fdf3.appspot.com/medias/lNTAeM1YqwftBP19xoPBAZiZCpF3/20210121132428_videotest1.mp4',
				},
			],
		},
	},
	page_web_pdf: {
		title: 'Nouvelle Page Web + PDF',
		data: {
			bgColor: '#012560',
			name: 'Page Web + PDF',
			type: NType.Page,
			children: [
				{ h: 90, name: 'Web', type: NType.Web, url: 'http://metro.fr', w: 63, x: 0, y: 0 },
				{
					bgColor: '#ededed',
					h: 10,
					layout: 'row',
					name: 'Navigation',
					type: NType.Block,
					w: 63,
					x: 0,
					y: 90,
					children: [
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'back' },
							text: '<',
							type: NType.Button,
							w: 8,
							x: 2,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'https://www.metro.fr/' },
							text: 'Accueil',
							type: NType.Button,
							w: 20,
							x: 12,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'https://www.metro.fr/services/creer-site-internet' },
							text: 'Créez un site web',
							type: NType.Button,
							w: 20,
							x: 32,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: { sendTo: 'PageWeb', url: 'https://www.metro.fr/services/menu-digital-restaurants' },
							text: 'Créez un menu digital',
							type: NType.Button,
							w: 20,
							x: 56,
							y: 12.5,
						},
						{
							bgColor: '#002866',
							color: '#ffffff',
							fontSize: 20,
							h: 75,
							onClick: {
								sendTo: 'PageWeb',
								url: 'https://www.metro.fr/services/carte-metro-reflexe/delai-de-paiement-differe',
							},
							text: 'Reflexe Plus',
							type: NType.Button,
							w: 20,
							x: 78,
							y: 12.5,
						},
					],
				},
				{
					h: 100,
					name: 'PDF',
					type: NType.Pdf,
					url: 'https://firebasestorage.googleapis.com/v0/b/cafe-1fdf3.appspot.com/o/files%2FMetroPDF.pdf?alt=media&token=ee8d0fda-4033-4b46-a382-787a5df6a921',
					w: 37,
					x: 63,
					y: 0,
				},
			],
		},
	},
	page_pdf_video: {
		title: 'Nouvelle Page PDF + Vidéo',
		data: {
			name: 'page vidéo',
			type: NType.Page,
			children: [
				{
					h: 100,
					w: 63,
					x: 0,
					y: 0,
					type: NType.Video,
					name: 'Vidéo',
					bgColor: '#000000',
					url: 'https://firebasestorage.googleapis.com/v0/b/cafe-1fdf3.appspot.com/o/medias%2FlNTAeM1YqwftBP19xoPBAZiZCpF3%2F20210121132428_videotest1.mp4?alt=media&token=0bd928b8-d2df-4d98-8143-510c0fd57a9d',
					gs: 'gs://cafe-1fdf3.appspot.com/medias/lNTAeM1YqwftBP19xoPBAZiZCpF3/20210121132428_videotest1.mp4',
				},
				{
					h: 100,
					name: 'PDF',
					type: NType.Pdf,
					url: 'https://firebasestorage.googleapis.com/v0/b/cafe-1fdf3.appspot.com/o/files%2FMetroPDF.pdf?alt=media&token=ee8d0fda-4033-4b46-a382-787a5df6a921',
					w: 37,
					x: 63,
					y: 0,
				},
			],
		},
	},
};

export default templates;