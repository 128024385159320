import { TextField } from '@mui/material';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function InputField({ label, itemId, prop }: FieldProp) {
	return (
		<PropDelete itemId={itemId} prop={prop}>
			<TextField
				id={`${itemId}-${String(prop)}`}
				label={label}
				value={nodes.getPropString(itemId, prop, '')}
				onChange={(e) => nodes.setPropString(itemId, prop, e.target.value)}
				size='small'
			/>
		</PropDelete>
	);
}