import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import nodes from '~src/controllers/NodesController';
import DeleteIcon from '@mui/icons-material/Backspace';
import { NData } from '~src/models/Node';
import PropRow from './PropRow';

export default function PropDelete({ itemId, prop, children }: { itemId: string; prop: keyof NData, children: ReactNode }): JSX.Element {
	return (
        <PropRow>
            {children}
            <IconButton
                color="default"
                onClick={() => nodes.setProp(itemId, prop, undefined)}
                size="large">
                <DeleteIcon />
            </IconButton>
        </PropRow>
    );
}