import { getAuth } from 'firebase/auth';
import firebaseApp from './firebaseApp';

export const auth = getAuth(firebaseApp);
auth.languageCode = 'fr';

const authErrors: { [code: string]: string } = {
	'auth/email-already-in-use': 'sign_emailAlreadyInUse',
	'auth/wrong-password': 'sign_wrongPassword',
	'auth/invalid-action-code': 'sign_invalidActionCode',
	'auth/expired-action-code': 'sign_expiredActionCode',
};

export function authError(error: any) {
	console.warn('authError', error);
	return authErrors[error.code] || `${error.code}: ${error.message}`;
}

// (window as any)._authStoreIds$ = authStoreIds$;

export default auth;
