import { useMemo } from "react";
import { dbObserveAny } from "~src/services/db";
import useObservable from "./useObservable";

export default function useDbVal<T>(path: string|undefined): T | undefined;
export default function useDbVal<T>(path: string|undefined, undefinedValue: T): T;
export default function useDbVal<T>(path: string|undefined, undefinedValue: any = undefined): T | any {
	const ob = useMemo(() => (path ? dbObserveAny<T>(path) : undefined), [path]);
	const value = useObservable(ob);
	return value === undefined ? undefinedValue : value;
}
