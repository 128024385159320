import { BehaviorSubject, Observable } from 'rxjs';
import { getDatabase, Query, ref, set, get, update, serverTimestamp, onDisconnect, onValue } from 'firebase/database';
import firebaseApp from './firebaseApp';
import { objectVal, listVal } from 'rxfire/database';
import { SoftInfo } from '~src/bridge/interfaces';

const db = getDatabase(firebaseApp);

export async function dbSet<T=any>(path?: string, value?: T) {
	if (!path) return;
    return await set(ref(db, path), value);
}

export async function dbGet<T>(path?: string) {
	if (!path) return undefined;
    return await get(ref(db, path)).then(snap => snap.val() as T|undefined);
}

export async function dbUpdate<T extends object>(path?: string, value?: Partial<T>) {
	if (!path || !value) return;
    return await update(ref(db, path), value);
}

export async function dbUpdateDevice(deviceId: string, value: Partial<DeviceDoc>) {
    await dbUpdate(dbDevicePath(deviceId), value);
}

export async function dbGetDevice(deviceId: string) {
    return await dbGet<DeviceDoc>(dbDevicePath(deviceId));
}

export function dbUpdateDeviceOnDisconnect(deviceId: string, value: Partial<DeviceDoc>) {
	const path = dbDevicePath(deviceId);
	if (!path) return;
    onDisconnect(ref(db, path)).update(value);
}

export function dbNow() {
	return serverTimestamp();
}

export const dbConnected$ = new BehaviorSubject(true);
const connectedRef = ref(db, ".info/connected");
onValue(connectedRef, (snap) => {
	dbConnected$.next(snap.val() === true);
});

// setInterval(async () => {
// 	const connected = await dbGet<boolean>(".info/connected");
// 	dbConnected$.next(connected||false);
// }, 60 * 1000);

export function dbDeviceConfigPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/config` : '';
}

export function dbDeviceConfigPlaylistPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/config/playlist` : '';
}

export function dbScreenWidthPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/screenWidth` : '';
}

export function dbScreenHeightPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/screenHeight` : '';
}

export function dbDeviceNamePath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/name` : '';
}

export function dbMonitorStartPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/monitorStart` : '';
}

export function dbMonitorEndPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/monitorEnd` : '';
}

export function dbDeviceCmdScriptPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/cmd/script` : '';
}

export function dbDeviceCmdPath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/cmd` : '';
}

export function dbDeviceInvokePath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}/invoke` : '';
}

export function dbDevicePath(deviceId?: string) {
	return deviceId ? `devices/${deviceId}` : '';
}

export function dbConsolePath(deviceId?: string) {
	return deviceId ? `console/${deviceId}` : '';
}

export function dbConsoleIsRemotePath(deviceId?: string) {
	return deviceId ? `console/${deviceId}/options/isRemote` : '';
}

export function dbConsoleLogsPath(deviceId?: string) {
	return deviceId ? `console/${deviceId}/logs` : '';
}

export function dbConsoleRunPath(deviceId?: string) {
	return deviceId ? `console/${deviceId}/run` : '';
}

export function dbObserveAny<T = any>(query: Query | string): Observable<T> {
	if (typeof query === 'string') query = ref(db, query);
	return objectVal<T>(query);
}

export function dbObserveItem<T extends DbItem = DbItemAny>(query: Query | string): Observable<T> {
	if (typeof query === 'string') query = ref(db, query);
	return objectVal<T>(query, { keyField: 'id' });
}

export function dbObserveItems<T extends DbItem = DbItemAny>(query: Query | string): Observable<T[] | null> {
	if (typeof query === 'string') query = ref(db, query);
	return listVal<T>(query, { keyField: 'id' });
}

export interface DbItem {
	id: string;
}

export type DbVal = string | number | { [key: string]: DbVal } | DbVal[];

export interface DbItemAny {
	id: string;
	[key: string]: DbVal;
}

export type DbTime = any;

export interface DbDoc {
	id: string;
	path: string;
	updateTime: DbTime;
}

export interface UserData {
	id: string;
	email: string;
	password: string;
}

export interface NetworkConfig {
    search: string;
    password: string;
}

export interface ConfigData {
	playlist?: any;
	networks: NetworkConfig[];
	user: UserData;
	loadURL?: string;
}

export interface ScriptData {
	last: string;
	script: string;
	timeout: number;
	data: string[];
	error: string[];
	exitCode: number | null;
	success: boolean | null;
}

export interface FileInfo {
	key: string;
	path: string;
	type: 'file' | 'dir' | '';
	size?: number;
	url?: string[];
	children?: string[];
	action?: 'upload' | 'open' | 'refresh' | 'unlink' | 'rmdir' | '';
	error?: string;
}

export interface DeviceInvoke {
    method?: string;
    begin?: number;
    end?: number;
    args?: any[];
    result?: string;
    error?: string;
}

export interface DeviceDoc extends DbDoc {
	id: string;
	cmd: ScriptData|null;
	invoke: DeviceInvoke|null;
	config: ConfigData;
	screenshotUrl: string|null;
	version: string;
	started: DbTime;
	connected: DbTime;
	disconnected: DbTime;
	isConnected: boolean;
	screenWidth?: number;
	screenHeight?: number;
	name?: string;
	files: {
		[key: string]: FileInfo;
	};
	users: {
		[id: string]: {
			email: string;
		};
	};
    updated: DbTime;
    runScript?: string;
    monitorStart?: string;
    monitorEnd?: string;
    monitorIf?: string;
    monitorOn?: boolean;
    monitorError?: string;
	bridge?: boolean;
	softInfo?: SoftInfo;
	kiosk?: boolean;
}
