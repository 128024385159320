import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

type SelectFieldProp = SelectProps &
	FieldProp & {
		options: ({ value: any; text: string } | string | number)[];
	};

export default function SelectField({ label, itemId, prop, options, ...rest }: SelectFieldProp) {
	const id = `${itemId}-${String(prop)}`;
	const labelId = `${id}-label`;
	return (
		<PropDelete itemId={itemId} prop={prop}>
			<FormControl size="small">
				<InputLabel id={labelId}>{label}</InputLabel>
				<Select
					labelId={labelId}
					id={id}
					value={nodes.getProp(itemId, prop)}
					onChange={(e) => nodes.setProp(itemId, prop, e.target.value)}
					label={label}
					{...rest}
				>
					<MenuItem value={undefined}></MenuItem>
					{options.map((v, i) =>
						typeof v === 'string' || typeof v === 'number' ? (
							<MenuItem key={i} value={v}>
								{String(v)}
							</MenuItem>
						) : (
							<MenuItem key={i} value={v.value}>
								{String(v.text)}
							</MenuItem>
						),
					)}
				</Select>
			</FormControl>
		</PropDelete>
	);
}
