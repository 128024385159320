const _styleFileMap: Record<string, HTMLElement> = {};
(window as any)._styleFileMap = _styleFileMap;

export default function addStyleFile(url: string) {
    return new Promise((resolve) => {
        if (_styleFileMap[url]) return resolve(true);
        const el = document.createElement('link');
        console.debug('addStyleFile', url, el);
        el.rel = 'stylesheet';
        el.type = 'text/css';
        el.onerror = (err) => {
            console.error('addStyleFile onerror', url, err)
            resolve(false);
        };
        el.onload = () => {
            console.debug('addStyleFile onload', url)
            resolve(true);
        };
        el.href = url;
        document.head.appendChild(el);
        _styleFileMap[url] = el;
    });
}