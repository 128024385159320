import { blockRegister } from './BlockFactory';
import Block from './Block';
import BlockButton from './BlockButton';
import BlockImage from './BlockImage';
import BlockVideo from './BlockVideo';
import BlockkPdf from './BlockPdf';
import BlockText from './BlockText';
import BlockWeb from './BlockWeb';
import BlockRoot from './BlockRoot';
import BlockYouTube from './BlockYouTube';
import { NType } from '~src/models/Node';

export default function blockRegisters() {
	blockRegister(NType.None, Block);
	blockRegister(NType.Block, Block);
	blockRegister(NType.Button, BlockButton);
	blockRegister(NType.Image, BlockImage);
	blockRegister(NType.Video, BlockVideo);
	blockRegister(NType.Youtube, BlockYouTube);
	blockRegister(NType.Pdf, BlockkPdf);
	blockRegister(NType.Text, BlockText);
	blockRegister(NType.Web, BlockWeb);
	blockRegister(NType.Root, BlockRoot);
	blockRegister(NType.Page, Block);
}
