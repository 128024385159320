import React, { useEffect, useState } from 'react';
import clsx from '~src/helpers/clsx';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import auth from '~src/services/auth';
import useObservable from '~src/hooks/useObservable';
import Button from '@mui/material/Button';
import { BehaviorSubject } from 'rxjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import currentUser$ from '~src/messages/currentUser$';

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	page: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflow: 'hidden',
	},
	error: {},
	body: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	topBar: {},
	topBarMenuBtn: {
		marginRight: theme.spacing(2),
	},
	topBarTitle: {
		flexGrow: 1,
	},
	drawerClose: {
		width: 0,
	},
	drawerOpen: {
		width: 220,
	},
	drawerPaper: {
		width: 220,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
}));

let nextBusyId = 0;
export const busyMap$ = new BehaviorSubject<{ [id: string]: boolean }>({});
export const pageError$ = new BehaviorSubject({ error: null as any, open: false });
export const pageInfo$ = new BehaviorSubject({ title: '', storeId: '' });

export function handleError(error: any) {
	console.error('Page.handleError', error);
	pageError$.next({ error, open: true });
}

export function busyStart() {
	const id = nextBusyId++;
	const next = { ...busyMap$.value, [id]: true };
	busyMap$.next(next);
	return () => {
		const next = { ...busyMap$.value };
		delete next[id];
		busyMap$.next(next);
	};
}

export function pagePromise(promise: Promise<any>) {
	console.debug('Page.pagePromise', promise);
	const busyStop = busyStart();

	return promise
		.then((result) => {
			console.debug('Page.busyPromise result', promise, result);
			busyStop();
			return result;
		})
		.catch((error) => {
			console.error('Page.busyPromise error', promise, error);
			busyStop();
			handleError(error);
		});
}

function ErrorDialog() {
	const { error, open } = useObservable(pageError$) || { error:'', open:false };

	const handleClose = () => {
		pageError$.next({ error, open: false });
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Une erreur est survenue</DialogTitle>
			<DialogContent>
				<DialogContentText>{error && (error.code || error.message)}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary" autoFocus>
					Fermer
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function PageContainer({ children }: { children: JSX.Element | JSX.Element[] }) {
	const classes = useStyles();
	const currentUser = useObservable(currentUser$);
	let { title } = useObservable(pageInfo$) || { title: '' };
	let [drawerOpen, setDrawerOpen] = useState(true);

	const handleLogout = () => {
		console.debug('TopBar handleLogout');
		auth.signOut();
	};

	return (
		<div className={classes.root}>
			<div className={classes.body}>
				<AppBar className={classes.topBar} position="static">
					<Toolbar variant="dense">
						{!drawerOpen && (
							<IconButton
								edge="start"
								className={classes.topBarMenuBtn}
								color="inherit"
								aria-label="menu"
								onClick={() => setDrawerOpen(true)}
							>
								<Icon>menu</Icon>
							</IconButton>
						)}
						<Typography variant="h6" className={classes.topBarTitle}>
							{title}
						</Typography>
						{currentUser && (currentUser.displayName || currentUser.email)}
						{currentUser && (
							<Button color="inherit" onClick={handleLogout}>
								DÉCONNEXION
							</Button>
						)}
					</Toolbar>
				</AppBar>
				{children}
			</div>
			<ErrorDialog />
		</div>
	);
}

export default function Page({
	className,
	title,
	storeId,
	children,
}: {
	className: string;
	title: string;
	storeId?: string;
	children: JSX.IntrinsicElements['main']['children'];
}) {
	const classes = useStyles();
	useEffect(() => pageInfo$.next({ title, storeId: storeId||'' }), [title, storeId]);
	return <main className={clsx(classes.page, className)}>{children}</main>;
}
