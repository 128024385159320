import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { fr } from 'date-fns/locale';
import React from 'react';
import nodes from '~src/controllers/NodesController';
import FieldProp from './FieldProp';
import PropDelete from './PropDelete';

export default function IntervalField({ label, itemId, prop }: FieldProp) {
    const val = nodes.getPropNumber(itemId, prop);
    const date = val ? new Date(val) : null;
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
			<PropDelete itemId={itemId} prop={prop}>
				<DatePicker
					label={label}
					value={date}
					onChange={(date: any) => {
						console.debug('DateField onChange', prop, date);
						const val = date ? date.getTime() : null;
						nodes.setPropNumber(itemId, prop, val)
					}}
					renderInput={(params: any) => <TextField id={`${itemId}-${String(prop)}`} {...params} />}
				/>
			</PropDelete>
		</LocalizationProvider>
	);
}