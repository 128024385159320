import { BehaviorSubject } from 'rxjs';
import nodes from '../controllers/NodesController';
import { debugAdd } from '~src/helpers/debug';
import { NAction } from '~src/models/Node';

export const action$ = new BehaviorSubject<NAction | undefined>(undefined);

export function actionClick(e: any, id: string) {
    console.debug('actionClick', id);
    const onClick = nodes.get(id).onClick;
    if (onClick && Object.keys(onClick).length > 0) {
        action$.next({ ...onClick });
    }
    // nodes.setSelectedId(id);
    // if (this.mode$.value === 'editor') {
    //     e.preventDefault();
    //     e.stopPropagation();
    // }
}

debugAdd({ action$ });

export default action$;