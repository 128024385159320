import { useEffect } from 'react';
import nodes from '~src/controllers/NodesController';

type BlockListener<T> = (itemId: string, type: string, e: T) => void;

const lsMap: { [key: string]: BlockListener<any>[] } = {};

export function getHandleBlockEvent<T = any>(itemId: string, types: string[]) {
	return (e: T) => {
		let id: string | undefined = itemId;
		while (id) {
			for (const type of types) for (const l of lsMap[id + type] || []) l(itemId, type, e);
			id = nodes.get(id).parentId;
		}
	};
}

export default function useBlockEventListener<T>(itemId: string, type: string, listener: BlockListener<T>) {
	useEffect(() => {
		const key = itemId + type;
		const l = listener;
		if (l) (lsMap[key] || (lsMap[key] = [])).push(l);
		return () => {
			const ls = lsMap[key];
			if (!ls) return;
			ls.splice(ls.indexOf(l), 1);
			if (ls.length === 0) delete lsMap[key];
		};
	}, [itemId, type, listener]);
}
