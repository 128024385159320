import { useState, useEffect } from 'react';

const isClient = typeof window === 'object';

function getSize() {
	return {
		width: isClient ? window.innerWidth : undefined,
		height: isClient ? window.innerHeight : undefined,
	};
}

export default function useWindowSize() {
	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (isClient) {
			const handleResize = () => setWindowSize(getSize());
			window.addEventListener('resize', handleResize);
			window.addEventListener('orientationchange', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('orientationchange', handleResize);
			};
		}
	}, []);

	return windowSize;
}
