import adminRole$ from './adminRole$';
import { debugAdd } from '~src/helpers/debug';
import { map, switchMap, catchError, combineLatest, of } from 'rxjs';
import { dbObserveAny, DeviceDoc, dbObserveItems, dbObserveItem, dbDevicePath } from '~src/services/db';

export const devices$ = adminRole$.pipe(
	switchMap((user) => {
		if (user.isAdmin) {
			return dbObserveItems<DeviceDoc>(`devices`);
		} else {
			const noDeviceMap: { [key: string]: boolean } = { email: true, storeName: true };
			return dbObserveAny<{ [deviceId: string]: boolean }>(`roles/${user.id}`).pipe(
				switchMap((deviceIdMap) =>
                    combineLatest(
						Object.keys(deviceIdMap)
							.filter((id) => noDeviceMap[id] === undefined)
							.map((deviceId) =>
								dbObserveItem<DeviceDoc>(dbDevicePath(deviceId)).pipe(
									// remove device on role error
									catchError((err) => {
										console.warn(dbDevicePath(deviceId), err);
										return of({} as DeviceDoc);
									}),
								),
							),
					).pipe(
						// remove device on role error
						map((devices) => devices.filter((d) => d)),
					),
				),
			);
		}
	}),
).pipe(
	map((devices) => {
		const devicesSorted = devices?.sort((a, b) => ((a.name||'') < (b.name||'') ? -1 : (a.name||'') > (b.name||'') ? 1 : 0));
		debugAdd({ devices: devicesSorted });
		return devicesSorted;
	}),
);

debugAdd({ devices$ });

export default devices$;