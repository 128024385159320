import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Icon from '@mui/material/Icon';
import clsx from '~src/helpers/clsx';
import useUrlParams from '~src/hooks/useUrlParams';
import CircularProgress from '@mui/material/CircularProgress';
import auth, { authError } from '~src/services/auth';
import { useTr } from '~src/services/tr';
import { logP } from '~src/services/log';
import { verifyPasswordResetCode, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, confirmPasswordReset, sendPasswordResetEmail } from 'firebase/auth';
import { dbUpdate } from '~src/services/db';

const APP_NAME = 'BoardScreen';
const APP_LINK = 'https://boardscreen.fr/';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href={APP_LINK}>
				{APP_NAME}
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		'&--in': {},
		'&--up': {},
		'&--forgot': {},
	},
	image: {
		backgroundImage: 'url(/ecran_accueil_mediactil.jpg)', // : 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		// backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundPosition: 'center',
		backgroundSize: 'contain',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
	},
	busy: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#ffffff88',
		zIndex: 100,
	},
	avatar: {
		margin: theme.spacing(1),
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	error: {
		// color: theme.palette.error.main,
		margin: theme.spacing(1),
		fontWeight: 'bold',
	},
}));

let _oobCode = '';

export default function Sign() {
	const classes = useStyles();
	const [state, setState] = useState({
		view: '' as '' | 'in' | 'up' | 'forgot' | 'resetPassword',
		email: '',
		password: '',
		remember: true,
		error: '',
		isBusy: false,
		firstName: '',
		lastName: '',
	});
	const tr = useTr();

	console.debug('SignPage', state);

	const { mode, oobCode } = useUrlParams();
	console.debug('useUrlParams', mode, oobCode);

	if (!_oobCode && oobCode) { //mode === 'resetPassword') {
		console.debug('resetPassword');
		_oobCode = oobCode;
		setState({ ...state, view: 'in', isBusy: true });
		verifyPasswordResetCode(auth, oobCode)
			.then((email) => {
				console.error('verifyPasswordResetCode', email);
				setState({ ...state, view: 'resetPassword', email, isBusy: false });
			})
			.catch((error) => {
				console.error('verifyPasswordResetCode', error);
				setState({ ...state, view: 'in', error: authError(error), isBusy: false });
			});
	}

	const view = state.view || 'in';
	const isIn = view === 'in';
	const isUp = view === 'up';
	const isForgot = view === 'forgot';
	const isResetPassword = view === 'resetPassword';

	console.debug('Sign', { view, isIn, isUp, isForgot, isResetPassword });

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.debug('Sign handleSubmit', state);

		const password = String(state.password);
		const email = String(state.email).trim();
		const firstName = String(state.firstName).trim();
		const lastName = String(state.lastName).trim();

		if (isIn) {
			setState({ ...state, error: '', isBusy: true });
			logP(() => signInWithEmailAndPassword(auth, email, password))
				.then(async (credential) => {
					if (!credential.user || !credential.user.uid) return;
					await dbUpdate(`users/${credential.user.uid}`, {
						email,
						firstName,
						lastName,
						signInTime: dbTimeNow(),
					});
					setState({ ...state, isBusy: false });
				})
				.catch((error) => setState({ ...state, error: authError(error), isBusy: false }));
			return;
		}
		if (isUp) {
			setState({ ...state, error: '', isBusy: true });
			logP(() => createUserWithEmailAndPassword(auth, email, password))
				.then(async (credential) => {
					if (!credential.user || !credential.user.uid) return;
					await updateProfile(credential.user, { displayName: `${firstName} ${lastName}`.trim() });
					await dbUpdate(`users/${credential.user.uid}`, {
						email,
						firstName,
						lastName,
						signUpTime: dbTimeNow(),
					});
					// await db.profile(credential.user.uid).merge({ email, firstName, lastName, signUpTime: db.serverTimestamp() });
					setState({ ...state, isBusy: false });
				})
				.catch((error) => setState({ ...state, error: authError(error), isBusy: false }));
			return;
		}
		if (isForgot) {
			setState({ ...state, error: '', isBusy: true });
			logP(() => sendPasswordResetEmail(auth, email))
				.then(() => setState({ ...state, isBusy: false }))
				.catch((error) => setState({ ...state, error: authError(error), isBusy: false }));
			return;
		}
		if (isResetPassword) {
			setState({ ...state, error: '', isBusy: true });
			logP(() => confirmPasswordReset(auth, oobCode, password))
				.then(() => signInWithEmailAndPassword(auth, email, password))
				.then(async (credential) => {
					if (!credential.user || !credential.user.uid) return;
					await dbUpdate(`users/${credential.user.uid}`, {
						email,
						signInTime: dbTimeNow(),
					});
					setState({ ...state, isBusy: false });
				})
				.catch((error) => setState({ ...state, error: authError(error), isBusy: false }));
			return;
		}
		setState({ ...state, error: 'sign_errorStateNotImplemented' });
	};

	return (
		<Grid container component="main" className={clsx(classes.root, `${classes.root}--${state}`)}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<Icon>lock_outlined</Icon>
					</Avatar>
					{state.error && <div className={classes.error}>{tr(state.error)}</div>}
					<Typography component="h1" variant="h5">
						{isIn && tr('sign_inTitle')}
						{isUp && tr('sign_upTitle')}
						{isForgot && tr('sign_forgotTitle')}
					</Typography>
					<form className={classes.form} noValidate onSubmit={handleSubmit}>
						{(isUp || isIn || isForgot || isResetPassword) && (
							<TextField
								key={view}
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label={tr('sign_email')}
								autoFocus
								name="email"
								autoComplete="email"
								value={state.email}
								onChange={(e) => setState({ ...state, email: e.target.value })}
								disabled={isResetPassword}
							/>
						)}
						{(isIn || isUp || isResetPassword) && (
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label={tr('sign_password')}
								type="password"
								id="password"
								autoComplete={isIn ? 'current-password' : 'new-password'}
								value={state.password}
								onChange={(e) => setState({ ...state, password: e.target.value })}
							/>
						)}
						{isUp && (
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										autoComplete="fname"
										name="firstName"
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="firstName"
										label={tr('sign_firstName')}
										value={state.firstName}
										onChange={(e) => setState({ ...state, firstName: e.target.value })}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="lastName"
										label={tr('sign_lastName')}
										name="lastName"
										autoComplete="lname"
										value={state.lastName}
										onChange={(e) => setState({ ...state, lastName: e.target.value })}
									/>
								</Grid>
							</Grid>
						)}
						{(isIn || isUp || isResetPassword) && (
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="secondary"
										checked={state.remember}
										onChange={(e) => {
											const remember = e.target.checked;
											// TODO setAuthRemember(remember);
											setState({ ...state, remember });
										}}
									/>
								}
								label={tr('sign_rememberMe')}
							/>
						)}
						<Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit}>
							{isIn && tr('sign_inSubmit')}
							{isUp && tr('sign_upSubmit')}
							{isForgot && tr('sign_forgotSubmit')}
							{isResetPassword && tr('sign_resetPasswordSubmit')}
						</Button>
						{isIn && (
							<Grid container>
								<Grid item xs>
									<Link href="#" variant="body2" onClick={() => setState({ ...state, error: '', view: 'forgot' })}>
										{tr('sign_forgotLink')}
									</Link>
								</Grid>
								<Grid item>
									<Link href="#" variant="body2" onClick={() => setState({ ...state, error: '', view: 'up' })}>
										{tr('sign_upLink')}
									</Link>
								</Grid>
							</Grid>
						)}
						{(isUp || isForgot) && (
							<Link href="#" variant="body2" onClick={() => setState({ ...state, error: '', view: 'in' })}>
								{tr('sign_inLink')}
							</Link>
						)}
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
					{state.isBusy && (
						<div className={classes.busy}>
							<CircularProgress />
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
}
function dbTimeNow() {
	throw new Error('Function not implemented.');
}

