import { BehaviorSubject } from 'rxjs';
import { debugAdd } from '~src/helpers/debug';
import { updateUrlParams, urlParams$ } from '~src/hooks/useUrlParams';

export enum PageMode {
	Open = "open",
    Edit = "edit",
    View = "view",
}

export const pageMode$ = new BehaviorSubject<PageMode|undefined>(undefined);

urlParams$.subscribe(urlParams => {
    if (urlParams.mode) {
        const pageMode = {
            [PageMode.Open]: PageMode.Open,
            [PageMode.Edit]: PageMode.Edit,
            [PageMode.View]: PageMode.View,
        }[urlParams.mode] || undefined;

        if (pageMode$.value !== pageMode) {
            console.debug('pageMode$ from urlParams', pageMode)
            pageMode$.next(pageMode);
        }
    }
});

pageMode$.subscribe(pageMode => {
    const urlParams = urlParams$.value;
    if (urlParams.mode !== pageMode) {
        console.debug('pageMode$ to urlParams', pageMode)
        updateUrlParams({ mode: pageMode });
    }
});

debugAdd({ pageMode$ });

export default pageMode$;