import { useState, useEffect } from 'react';
import { Observable } from 'rxjs';

export default function useObservable<T>(observable: Observable<T> & { value: T }): T;
export default function useObservable<T>(observable?: Observable<T>): T | undefined;
export default function useObservable<T, U=T>(observable: Observable<T> | undefined, initValue: U): T | U;
export default function useObservable<T, U=T>(observable?: Observable<T> & { value?: T }, initValue?: U): T | U | undefined {
	const v = observable?.value !== undefined ? observable.value : initValue;
	const [value, setValue] = useState(v);

	useEffect(() => {
		setValue(v);
		if (!observable) return;
		const s = observable.subscribe((next: T) => setValue(next));
		return () => s.unsubscribe();
	}, [observable, v]);

	return value;
}

// export default function useObservable<T>(observable: IObservable<T>, options: { initValue?: any, onError?: (error: any) => void } = {}): T {
// 	const [value, setValue] = useState<T>(options.initValue !== undefined ? options.initValue : observable ? observable.value : null);
// 	useEffect(() => {
// 		if (!observable) return;
// 		const s = observable.subscribe((next: T) => setValue(next), options.onError);
// 		return () => s.unsubscribe();
// 	}, [observable, options.onError]);
// 	return value;
// }

// export function useObservableGetter<T, U = T>(
// 	observable: IObservable<T>,
// 	getter: (next: T) => U,
// ): U {
// 	const [value, setValue] = useState<U>(() => getter(observable.value));
// 	useEffect(() => {
// 		const s = observable.subscribe((next: T) => setValue(getter(next)));
// 		return () => s.unsubscribe();
// 	}, [observable, getter]);
// 	return value;
// }


// export function useObservableGetter<T, U = T>(
// 	observable: IObservable<T>,
// 	options: {
// 		initValue?: any,
// 		getter?: (next: T) => U,
// 		onError?: (error: any) => void,
// 		deps?: React.DependencyList,
// 	} = {}
// ): U {
// 	const getter = options.getter || ((next: T) => next as any as U);
// 	const [value, setValue] = useState<U>(options.initValue || (() => getter(observable.value)));
// 	useEffect(() => {
// 		const s = observable.subscribe((next: T) => setValue(getter(next)), options.onError);
// 		return () => s.unsubscribe();
// 	}, options.deps || [observable, options.getter]);
// 	return value;
// }
