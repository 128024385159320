import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from './firebaseApp';

export const functions = getFunctions(firebaseApp);

export function getFunctionCallable(name: string) {
	return httpsCallable(functions, name);
};

export async function getUserId(email: string) {
	const r = await getFunctionCallable('getUserId')({ email });
	return typeof r.data === 'string' && r.data.length ? r.data : null;
};