import React, { ReactNode, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from '~src/helpers/clsx';

const minDrawerWidth = 20;
const maxDrawerWidth = 1000;

const useStyles = makeStyles((theme) => ({
	// root: {
	// 	borderLeft: '1px solid #c3c3c3',
	// 	position: 'relative',
	// 	background: 'white',
	// 	boxShadow: theme.shadows[10],
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// },
	drawer: {
		flexShrink: 0,
		borderLeft: '1px solid #c3c3c3',
		position: 'relative',
		background: 'white',
		boxShadow: theme.shadows[10],
		display: 'flex',
		flexDirection: 'column',
	},
	dragger: {
		width: '5px',
		cursor: 'ew-resize',
		padding: '4px 0 0',
		borderTop: '1px solid #ddd',
		position: 'absolute',
		top: 0,
		bottom: 0,
		zIndex: 100,
		backgroundColor: '#f4f7f9',
	},
	draggerLeft: {
		right: 0,
	},
	draggerRight: {
		left: 0,
	}
}));

export default function EditorDrawer({ anchor, width, children }: { anchor?: 'left' | 'right'; width: number; children: ReactNode }) {
	const classes = useStyles();
	const [drawerWidth, setDrawerWidth] = React.useState(width);

	const handleMouseDown = () => {
		document.addEventListener('mouseup', handleMouseUp, true);
		document.addEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mouseup', handleMouseUp, true);
		document.removeEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseMove = useCallback((e) => {
		if (anchor === 'left') {
			const newWidth = e.clientX - document.body.offsetLeft;
			if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
				setDrawerWidth(newWidth);
			}
		} else {
			const newWidth = document.body.offsetWidth - e.clientX;
			console.debug('handleMouseMove', e.clientX, document.body.offsetWidth, newWidth);
			if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
				setDrawerWidth(newWidth);
			}
		}
	}, [anchor]);

	return (
		<div className={classes.drawer} style={{ width: drawerWidth }}>
			{children}
			<div className={clsx(classes.dragger, anchor === 'left' ? classes.draggerLeft : classes.draggerRight)} onMouseDown={handleMouseDown} />
		</div>
	);
}
